import React, { FC } from "react"
import { Create } from "../../_design"
import ScaleSurveyForm from "./ScaleSurveyForm"

const ScaleSurveyCreate: FC = () => {
  return (
    <Create redirect="list">
      <ScaleSurveyForm />
    </Create>
  )
}

export default ScaleSurveyCreate
