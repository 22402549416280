import * as htmlToImage from "html-to-image"
import { jsPDF } from "jspdf"
import { SurveyInstanceRecord } from "../../../../types"

async function createPdf({ doc, elements }: { doc: jsPDF; elements: HTMLCollectionOf<Element> }) {
  let top = 20
  const padding = 10

  for (let i = 0; i < elements.length; i++) {
    const el = elements.item(i) as HTMLElement
    const imgData = await htmlToImage.toPng(el)

    let elHeight = el.offsetHeight
    let elWidth = el.offsetWidth

    const pageWidth = doc.internal.pageSize.getWidth()

    if (elWidth > pageWidth) {
      const ratio = pageWidth / elWidth
      elHeight = elHeight * ratio - padding
      elWidth = elWidth * ratio - padding
    }

    // Add each image on a new page
    // const pageHeight = doc.internal.pageSize.getHeight()
    // if (top + elHeight > pageHeight) {
    // doc.addPage()
    // top = 20
    // }

    doc.addImage(imgData, "PNG", padding, top, elWidth, elHeight, `image${i}`)
    if (i + 1 < elements.length) {
      doc.addPage()
      top = 20
    }
    // top += elHeight
  }
}

export async function exportMultipleChartsToPdf(record: SurveyInstanceRecord) {
  const doc = new jsPDF("p", "px") // (1)

  const elements = document.getElementsByTagName("canvas") // (2)

  await createPdf({ doc, elements }) // (3-5)

  const now = new Date().toISOString().split(".")[0]
  doc.save(`${record.name}-Results-${now}.pdf`) // (6)
}
