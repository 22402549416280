const API_PREFIX: string = import.meta.env.VITE_API_PREFIX as string

export const LoginPath: string = `${API_PREFIX}/auth/login`
export const SignUpPath: string = `${API_PREFIX}/auth/signup`
export const ForgotPasswordPath: string = `${API_PREFIX}/auth/forgotPassword`
export const ResetPasswordPath: string = `${API_PREFIX}/auth/resetPassword`

type apiCustomRoutesProps = {
  accounts: {
    Profile: string
    UploadPicture: string
    ChangePassword: string
  }
}

export const apiCustomRoutes: apiCustomRoutesProps = {
  accounts: {
    Profile: `${API_PREFIX}/Accounts/Profile`,
    UploadPicture: `${API_PREFIX}/Accounts/UploadPicture`,
    ChangePassword: `${API_PREFIX}/Accounts/ChangePassword`,
  },
}
