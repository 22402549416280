import React, { FC, useCallback } from "react"
import Button from "@mui/material/Button"
import EmailIcon from "@mui/icons-material/Email"
import invitationService from "../../../services/invitation-service"
import { useNotify, useRecordContext, useRefresh } from "react-admin"
import { SurveyInstanceRecord } from "../../../types"

const SendEmailsButton: FC = () => {
  const record = useRecordContext<SurveyInstanceRecord>()
  const refresh = useRefresh()
  const notify = useNotify()

  const onClick = useCallback(async () => {
    await invitationService.sendInstanceInvitations(record?.id ?? -1)
    refresh()
    notify("Survey invitations sent successfully", { type: "success" })
  }, [record, refresh, notify])

  return (
    <Button
      disabled={record.invitedTotal > 0}
      color="secondary"
      startIcon={<EmailIcon />}
      onClick={(e) => {
        e.stopPropagation()
        onClick()
      }}
    >
      Send Emails
    </Button>
  )
}

export default SendEmailsButton
