export default {
  domain: { dashboard: { widgets: {} } },
  account: {
    profile: {
      pictureUpload: {
        success: "Account picture has been updated!",
        failure: "Picture update has failed!",
      },
      updated: {
        success: "Account details have been updated!",
        failure: "Update has failed!",
      },
    },
  },
  configuration: {
    update: {
      success: "Configuration updated successfully!",
      failure: "Configuration update has failed!",
    },
  },
  fields: {
    amount: "Amount",
    date: "Date",
    description: "Description",
    name: "Name",
    status: "Status",
  },
  successMessages: {
    created: "%{resourceName} created successfully!",
    edited: "%{resourceName} edited successfully!",
  },
  userMenu: { about: "About", profile: "Profile", configuration: "Configuration" },
  menu: {
    users: "Users",
    surveyVersions: "Surveys",
    companies: "Companies",
    responses: "Responses",
    scaleSurveys: "Scale Surveys",
    matrixSurveys: "Matrix Surveys",
    sliderSurveys: "Slider Surveys",
    textboxSurveys: "Textbox Surveys",
    surveyInstances: "Surveys",
    ocaiSurveys: "OCAI Surveys",
  },
  inputs: {
    slider: { errorInitialValue: "Choose different value from the initial one." },
  },
  completedSurvey: { thankYou: "Thank you for completing the survey!" },
}
