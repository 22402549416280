﻿import React, { FC } from "react"
import {
  List,
  Datagrid,
  //TextField,
  EmailField,
  EditButton,
  DeleteButton,
  ChipFieldArray,
  // CompactList,
  FullNameField,
} from "../_design"

const UsersList: FC = () => {
  return (
    <List
      hasCreate={false}
      // responsiveView={
      //     <CompactList
      //         primaryItems={[
      //             <TextField source="fullName" />,
      //             <ChipFieldArray source="roles" />,
      //         ]}
      //         secondaryItems={[<TextField source="email" />]}
      //         actions={[<EditButton />, <DeleteButton />]}
      //     />
      // }
    >
      <Datagrid rowClick="show">
        {/* <FullNameField source="fullName" label="Name" /> // this should be fixed in ra-compact-ui*/}
        <EmailField source="email" />
        <ChipFieldArray source="roles" />
        <EditButton />
        <DeleteButton />
      </Datagrid>
    </List>
  )
}

export default UsersList
