import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import CardMedia from "@mui/material/CardMedia"
import Typography from "@mui/material/Typography"
import React, { FC } from "react"

const styles = {
  root: {
    display: "flex",
    justifyContent: "center",
  },
  card: {
    textAlign: "center",
    height: "fit-content",
    marginTop: "10%",
  },
}

type MyError = Error & {
  body: string
  status: number
}

type Props = { error?: MyError; message?: string }

const CustomError: FC<Props> = ({ error, message }) => (
  <div style={styles.root}>
    <Card sx={styles.card}>
      <CardContent>
        <CardMedia component="img" image="images/error.webp" />
        <Typography variant="h5" component="h2">
          Oops! {(message || error?.body) ?? "Something went wrong"}
        </Typography>
      </CardContent>
    </Card>
  </div>
)

export default CustomError
