import { useCallback } from "react"
import { useNotify } from "react-admin"
import { FileToUploadProps } from "../../../types"

const MAX_FILE_SIZE: number = 150000
const ALLOWED_FILE_TYPES: string[] = ["image/png", "image/jpeg", "image/gif"]

type TransformImageFile = (е: React.ChangeEvent<HTMLInputElement>) => FileToUploadProps | undefined

export const useTransformImageFile = (): TransformImageFile => {
  const notify = useNotify()

  const transformImage: TransformImageFile = useCallback(
    (e) => {
      // Extract the file
      const files = e.target.files ?? []
      const file: File = files[0]

      // Validate file type
      if (ALLOWED_FILE_TYPES.every((type: string) => file.type !== type)) {
        notify(`'${file.type}' file type is not a supported format`, { type: "warning" })
        return
      }

      // Validate file size
      if (file.size > MAX_FILE_SIZE) {
        notify(`File is too large, please pick a smaller file (limit 15mb)`, { type: "warning" })
        return
      }

      return raTransformFileOnUpload(file)
    },
    [notify]
  )

  return transformImage
}

const raTransformFileOnUpload = (file: File) => {
  if (!(file instanceof File)) {
    return file
  }

  const preview: string = URL.createObjectURL(file)
  const transformedFile = {
    rawFile: file,
    url: preview,
    title: file.name,
  }

  return transformedFile
}
