import { Box, Divider, Grid, Radio, Typography } from "@mui/material"
import FormControlLabel from "@mui/material/FormControlLabel"
import React, { FC, useContext, useEffect } from "react"
import { MatrixQuestionProps, MatrixStatementAnswer, Statement } from "../../../types/matrix-survey"
import { isSmallScreen } from "../../../utils/common-utils"
import { MatrixSurveyAnswersContext } from "../context/MatrixAnswersContext"

const isMobile = isSmallScreen()

type MatrixStatementQuestionProps = {
  statement: Statement
  labels?: string[]
  weights: number[]
}

const MatrixStatementQuestion: FC<MatrixStatementQuestionProps> = ({
  statement,
  labels,
  weights,
}) => {
  const { updateAnswer, getAnswer } = useContext(MatrixSurveyAnswersContext)
  const statementAnswersMap = getAnswer(statement.matrixQuestionId)
  const selectedValue = statementAnswersMap?.get(statement.id)?.value

  const onClick = (value: number) => {
    const statementAnswer: MatrixStatementAnswer = {
      matrixAnswerId: statement.matrixQuestionId,
      matrixQuestionStatementId: statement.id,
      value: value,
    }

    updateAnswer(
      statement.matrixQuestionId,
      getAnswer(statement.matrixQuestionId).set(statement.id, statementAnswer)
    )
  }

  useEffect(() => {
    if (getAnswer(statement.matrixQuestionId) === undefined) {
      // NOTE: Since we store statement answers in a map, we should initialize the map if its undefined
      updateAnswer(statement.matrixQuestionId, new Map<number, MatrixStatementAnswer>())
    }
  }, [])

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-evenly"
      sx={isMobile ? { padding: "25px 0px", flexDirection: "column" } : {}}
    >
      {weights.map((val, index: number) => (
        <FormControlLabel
          key={statement.id + index}
          value={val}
          onClick={() => onClick(val)}
          checked={selectedValue === val}
          control={<Radio size="medium" />}
          labelPlacement="end"
          label={<Typography color="textSecondary">{labels?.[index] ?? ""}</Typography>}
        />
      ))}
    </Grid>
  )
}

export const MatrixQuestion: FC<MatrixQuestionProps> = ({ columnLabels, question, index }) => {
  const labels = columnLabels.map((columnLabel) => columnLabel.label)
  const weights = columnLabels.map((columnLabel) => columnLabel.weight)
  const statements = question.statements

  return (
    <Box sx={{ margin: "1rem 0 0 0" }} key={question.id}>
      {!isMobile ? (
        <Grid container direction="row" justifyContent="space-evenly" alignItems="center">
          <Grid item xs={4}></Grid>
          <Grid item xs={8}>
            {index === 0 ? (
              <Grid container direction="row" justifyContent="space-evenly" alignItems="center">
                {labels.map((label) => (
                  <Grid key={label}>
                    <Box
                      display={{
                        width: "5rem",
                        textAlign: "center",
                      }}
                    >
                      {label}
                    </Box>
                  </Grid>
                ))}
              </Grid>
            ) : null}
          </Grid>
          <Grid item xs={4}>
            <Box display={{ xs: "block", sm: "flex", margin: "1rem" }}>
              <Typography variant="body1">
                <Box
                  sx={{
                    fontWeight: "bold",
                  }}
                >
                  {question.text}{" "}
                </Box>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={8}></Grid>
          {statements.map((statement) => (
            <>
              <Grid item xs={4}>
                <Box
                  display={{
                    xs: "block",
                    sm: "flex",
                    margin: "1rem",
                  }}
                >
                  <Typography variant="body1">{statement.text}</Typography>
                </Box>
              </Grid>
              <Grid item xs={8}>
                <MatrixStatementQuestion statement={statement} weights={weights} />
              </Grid>
            </>
          ))}
        </Grid>
      ) : (
        <>
          {/* MOBILE */}
          {/* Question Text */}
          <Grid item sm={12}>
            <Box display={{ xs: "block", sm: "flex" }}>
              <Typography variant="body1">
                <Box
                  sx={{
                    fontWeight: "bold",
                  }}
                >
                  {question.text}{" "}
                </Box>
              </Typography>
            </Box>
          </Grid>

          {/* Statements & Radio buttons */}
          {statements.map((statement) => (
            <>
              <Grid>
                {/* Statement */}
                <Divider orientation="horizontal" sx={{ margin: "25px" }} />
                <Typography variant="body1">{statement.text}</Typography>
              </Grid>
              <Grid sm={12}>
                {/* Radio Button */}
                <MatrixStatementQuestion statement={statement} weights={weights} labels={labels} />
              </Grid>
            </>
          ))}
        </>
      )}
    </Box>
  )
}
