import React, { FC } from "react"
import { Edit } from "react-admin"
import UserTitle from "./common/UserTitle"
import UsersForm from "./UsersForm"

const UsersEdit: FC = () => {
  return (
    <Edit title={<UserTitle />} redirect="list">
      <UsersForm />
    </Edit>
  )
}

export default UsersEdit
