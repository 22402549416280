import React, { FC } from "react"
import { ArrayInput, SimpleFormIterator, TextInput } from "../../_design"
import { BaseSurveyForm } from "../BaseSurveyForm"
import { ChapterArrayInput } from "../ChapterArrayInput"

export const OcaiSurveyForm: FC = () => {
  return (
    <BaseSurveyForm>
      <ChapterArrayInput>
        <ArrayInput source="questions" label="Questions">
          <SimpleFormIterator fullWidth getItemLabel={(index) => `${index + 1}.`}>
            {/* This field is required in the DB but we don't need it here and the input is hidden and auto-filled */}
            <TextInput source="text" defaultValue="default_value" sx={{ display: "none" }} />
            <TextInput source="answerA" label="Answer A" multiline fullWidth />
            <TextInput source="answerB" label="Answer B" multiline fullWidth />
            <TextInput source="answerC" label="Answer C" multiline fullWidth />
            <TextInput source="answerD" label="Answer D" multiline fullWidth />
          </SimpleFormIterator>
        </ArrayInput>
      </ChapterArrayInput>
    </BaseSurveyForm>
  )
}
