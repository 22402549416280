import { arrayRange } from "../components/participation/scale-survey-parts/inputs/input-common"
import { SurveyTypeEnum, likertLabels } from "../constants/survey.constants"
import { SurveyLocale } from "../types"

type GetScaleAnswersLabelsProps = {
  lowerLimit: number
  upperLimit: number
  scaleType: string
  locale: SurveyLocale
}

export const getScaleAnswersLabels = ({
  lowerLimit,
  upperLimit,
  scaleType,
  locale,
}: GetScaleAnswersLabelsProps) => {
  if (scaleType === "Likert") {
    return likertLabels[locale ?? "EN"]
  }
  return arrayRange(lowerLimit, upperLimit, 1)
}

export const parseSurveyMapId = (
  surveyMapId: string
): { typeId: SurveyTypeEnum; surveyId: number } => {
  const mapSplit = surveyMapId?.split("-")
  const typeId = Number.parseInt(mapSplit?.[0])
  const surveyId = Number.parseInt(mapSplit?.[1])

  return { typeId, surveyId }
}
