import React, { FC } from "react"
import { ArrayInput, BooleanInput, SimpleFormIterator, TextInput } from "react-admin"
import { BaseSurveyForm } from "../BaseSurveyForm"
import { ChapterArrayInput } from "../ChapterArrayInput"

const TextboxSurveyForm: FC = () => {
  return (
    <BaseSurveyForm>
      <ChapterArrayInput>
        <ArrayInput source="questions" label="Questions">
          <SimpleFormIterator fullWidth getItemLabel={(index) => `${index + 1}.`}>
            <TextInput source="text" label="Text" multiline fullWidth isRequired />
            <BooleanInput source="isRequired" label="Required" defaultValue={true} />
          </SimpleFormIterator>
        </ArrayInput>
      </ChapterArrayInput>
    </BaseSurveyForm>
  )
}

export default TextboxSurveyForm
