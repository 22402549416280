import React from "react"
import { FullNameField } from "../../_design"

type UserRecord = {
  email: string
  firstName: string
  fullName: string
  id: number
  lastName: string
  picture: null | string // Assuming picture can be a URL or null
  roles: string[]
}

type Props = {
  record?: UserRecord
}

//TODO: AvatarField (created by FullNameField) Invalid prop `record` of type `object` supplied to `AvatarField`, expected `string`

const UserTitle = ({ record }: Props) =>
  record ? <FullNameField record={record} size="32" /> : null

export default UserTitle
