import { AuthAdmin } from "ra-auth-ui"
import React from "react"
import { CustomRoutes, Resource } from "react-admin"
import { Route } from "react-router-dom"
import { Dashboard, crudResources } from "./components"
import { ExtendedLayout } from "./components/layout/ExtendedLayout"
import { Participation } from "./components/participation"
import { HomePage } from "./components/static-pages"
import PrivacyPolicy from "./components/static-pages/PrivacyPolicy/PrivacyPolicy"
import TermsAndConditions from "./components/static-pages/TermsAndConditions/TermsAndConditions"
import { About } from "./components/static-pages/about"
import { resourcesMap } from "./constants"
import { authProvider, dataProvider, i18nProvider, theme } from "./ra-providers"
import { ResourceItem } from "./types"

export const App = () => {
  return (
    <AuthAdmin
      title="OCAI"
      // profilePage={AccountProfile}
      authOptions={{ loginRedirectPath: "/surveyInstances" }}
      authProvider={authProvider}
      dataProvider={dataProvider}
      i18nProvider={i18nProvider}
      theme={theme}
      layout={ExtendedLayout}
    >
      <CustomRoutes noLayout>
        <Route path="/" element={<HomePage />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/participate/:id/:user" element={<Participation />} />
      </CustomRoutes>
      <CustomRoutes>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/about" element={<About />} />
      </CustomRoutes>
      {addCrudResources(crudResources, null)}
      <Resource name={resourcesMap.allSurveys.basePath} />
      <Resource name={resourcesMap.scaleSurveyResult.basePath} />
      <Resource name={resourcesMap.sliderSurveyResult.basePath} />
      <Resource name={resourcesMap.textboxSurveyResult.basePath} />
      <Resource name={resourcesMap.matrixSurveyResult.basePath} />
      <Resource name={resourcesMap.employees.basePath} />
    </AuthAdmin>
  )
}

const addCrudResources = (
  resCollection: ResourceItem[],
  permissions: string[] | null
): JSX.Element[] => {
  return resCollection.map((x: ResourceItem, i: number) => (
    // hasAccessToResource(permissions, x.rolesWithAccess) &&
    <Resource key={i} name={x.basePath} {...x.crud} icon={x.icon} options={x.options} />
  ))
}
