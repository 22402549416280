import React, { FC } from "react"
import { Edit } from "../_design"
import CompaniesForm from "./CompaniesForm"
import { useNotify } from "react-admin"

const CompaniesEdit: FC = () => {
  const notify = useNotify()

  const onError = (error: any) => {
    notify(
      `Could not update: ${typeof error.body === "string" ? error.body : "Check form for errors!"}`,
      { type: "error" }
    )
  }

  return (
    <Edit
      redirect="show"
      mutationMode="pessimistic"
      mutationOptions={{
        onError: onError,
      }}
    >
      <CompaniesForm />
    </Edit>
  )
}

export default CompaniesEdit
