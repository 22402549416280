import { resourcesMap } from "../../../constants"
import ScaleSurveyCreate from "./ScaleSurveyCreate"
import ScaleSurveyEdit from "./ScaleSurveyEdit"
import ScaleSurveyList from "./ScaleSurveyList"

export default {
  crud: {
    list: ScaleSurveyList,
    edit: ScaleSurveyEdit,
    create: ScaleSurveyCreate,
  },
  ...resourcesMap.scaleSurveys,
}
