import React, { FC } from "react"
import {
  List,
  Datagrid,
  TextField,
  NumberField,
  // BooleanField,
  // FunctionField,
  EditButton,
  DeleteButton,
} from "../../_design"

const ScaleSurveyList: FC = () => {
  return (
    <List>
      <Datagrid rowClick="show">
        <NumberField source="id" />
        <TextField source="name" />
        <TextField source="scaleType" />
        {/* <FunctionField render={(x) => x.members.length} label="Members" /> */}
        {/* <BooleanField
          source="activeSurvey"
          // TrueIcon={ToggleOnIcon}
          // FalseIcon={BlockIcon}
        /> */}
        {/* <TextField source="industry" /> */}
        <EditButton />
        <DeleteButton />
      </Datagrid>
    </List>
  )
}

export default ScaleSurveyList
