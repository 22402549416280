import Box from "@mui/material/Box"
import React, { useMemo } from "react"
import { useLocation, useParams } from "react-router-dom"
import { SurveyTypeEnum } from "../../constants/survey.constants"
import { NotAdminLayout } from "../_design"
import { BaseWizard } from "../wizard"
import { ParticipationContext } from "./ParticipationContext"
import { useGetSurvey } from "./common"
import CustomError from "./common/CustomError"
import { matrixSurveyWizardDetails } from "./matrix-survey-parts"
import { ocaiSurveyWizardDetails } from "./ocai-survey-parts"
import { scaleSurveyWizardDetails } from "./scale-survey-parts"
import { sliderSurveyWizardDetails } from "./slider-survey-parts"
import { textboxSurveyWizardDetails } from "./textbox-survey-parts"

const surveyTypesMap: Record<SurveyTypeEnum, any> = {
  // TODO - changed type response should be string
  1: scaleSurveyWizardDetails,
  2: sliderSurveyWizardDetails,
  3: textboxSurveyWizardDetails,
  4: matrixSurveyWizardDetails,
  5: ocaiSurveyWizardDetails,
}

const Participation = () => {
  const { id: surveySlug = "", user: userSlug = "" } = useParams<{
    id: string
    user: string
  }>()

  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const token = queryParams.get("token") || ""

  const { loading, data, error } = useGetSurvey(surveySlug, userSlug, token)

  const value = useMemo(() => ({ surveySlug, userSlug, token }), [surveySlug, userSlug, token])

  if (error)
    return (
      <NotAdminLayout>
        <Box sx={{ height: "100vh" }}>
          <CustomError error={error} />
        </Box>
      </NotAdminLayout>
    )

  return (
    <NotAdminLayout>
      <ParticipationContext.Provider value={value}>
        {!loading && data ? (
          <BaseWizard
            steps={surveyTypesMap[data.type as SurveyTypeEnum].convertSurveyToStepsFn(
              data.survey,
              data.type
            )}
            onComplete={surveyTypesMap[data.type as SurveyTypeEnum].onComplete}
            AnswersProvider={surveyTypesMap[data.type as SurveyTypeEnum].provider}
            AnswersContext={surveyTypesMap[data.type as SurveyTypeEnum].context}
          />
        ) : (
          <div>Loading</div>
        )}
      </ParticipationContext.Provider>
    </NotAdminLayout>
  )
}

export default Participation
