import { SurveyLocale } from "../types"
import { resourcesMap } from "./resources.constants"

export enum SurveyTypeEnum {
  Scale = 1,
  Slider = 2,
  Textbox = 3,
  Matrix = 4,
  Ocai = 5,
}

type SurveyTypeConfig = {
  id: number
  name: string
  shortName: string
  resource: string
  resultsPath: string
}

export const surveyConfigs: Record<SurveyTypeEnum, SurveyTypeConfig> = {
  1: {
    id: 1,
    name: "Scale Survey",
    shortName: "Scale",
    resource: resourcesMap.scaleSurveys.basePath,
    resultsPath: resourcesMap.scaleSurveyResult.basePath,
  },
  2: {
    id: 2,
    name: "Slider Survey",
    shortName: "Slider",
    resource: resourcesMap.sliderSurveys.basePath,
    resultsPath: resourcesMap.sliderSurveyResult.basePath,
  },
  3: {
    id: 3,
    name: "Textbox Survey",
    shortName: "Textbox",
    resource: resourcesMap.textboxSurveys.basePath,
    resultsPath: resourcesMap.textboxSurveyResult.basePath,
  },
  4: {
    id: 4,
    name: "Matrix Survey",
    shortName: "Matrix",
    resource: resourcesMap.matrixSurveys.basePath,
    resultsPath: resourcesMap.matrixSurveyResult.basePath,
  },
  5: {
    id: 5,
    name: "Ocai Survey",
    shortName: "Ocai",
    resource: resourcesMap.ocaiSurveys.basePath,
    resultsPath: resourcesMap.ocaiSurveyResult.basePath,
  },
}

export const likertLabels: Record<SurveyLocale, string[]> = {
  EN: ["Strongly Disagree", "Disagree", "Neither agree nor disagree", "Agree", "Strongly agree"],
  BG: [
    "Изобщо не съм съгласен",
    "Не съм съгласен",
    "Нито съм съгласен, нито несъгласен",
    "Съгласен съм",
    "Напълно съм съгласен",
  ],
}

type SliderResultsFormatsProps = {
  id: string
  name: string
}

export const sliderResultsFormat: SliderResultsFormatsProps[] = [
  {
    id: "MultiAxis",
    name: "MultiAxis",
  },
  {
    id: "SingleLine",
    name: "SingleLine",
  },
]
