import { MatrixAnswers } from "../components/participation/context"
import { Answers } from "../types"

export const isSmallScreen = (): boolean => window.matchMedia("(max-width: 40em)").matches

export const scrollToTop = () => window.scrollTo({ top: 0, left: 0, behavior: "smooth" })

export const convertDateToDDMMYYYY = (date: Date) =>
  new Date(date).toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  })

export const setAnswersAndGDPRLocalVariablesToDefault = () => {
  localStorage.setItem("TempSurveyAnswers", "{}")
  localStorage.setItem("CheckedToCAndGDPR", "false")
}

export const getCheckedToCAndGDPRFromLocalStorage = () => {
  return localStorage.getItem("CheckedToCAndGDPR")
}

export const setCheckedToCAndGDPRToLocalStorage = (value: boolean | null) => {
  value ? localStorage.setItem("CheckedToCAndGDPR", JSON.stringify(value)) : false
}

export const saveSurveyAnswersToLocalStorage = <TAnswer = unknown>(answers: TAnswer) => {
  if (areAnswersMap(answers)) {
    localStorage.setItem(
      "TempSurveyAnswers",
      stringifyMapAnswers(answers as Answers<MatrixAnswers>)
    )
  } else {
    localStorage.setItem("TempSurveyAnswers", JSON.stringify(answers))
  }
}

export const getSurveyAnswersFromLocalStorage = () => {
  const storedAnswers = localStorage.getItem("TempSurveyAnswers")
  if (isJSONAnswersMap(storedAnswers)) {
    return storedAnswers ? tryParseMapAnswers(storedAnswers) : null
  } else {
    return storedAnswers ? JSON.parse(storedAnswers) : null
  }
}

export const tryParseMapAnswers = (answers: string | null) => {
  if (!answers) {
    return null
  }

  try {
    let answersMap: Map<number, string> = new Map(JSON.parse(answers))

    if (answersMap !== undefined) {
      let parsedAnswers: Answers<MatrixAnswers> = {}
      answersMap.forEach((value, key) => {
        parsedAnswers = {
          ...parsedAnswers,
          [Number(key)]: new Map(JSON.parse(value || "")),
        }
      })

      return parsedAnswers
    }

    return null
  } catch (e) {
    return null
  }
}

export const areAnswersMap = (answers: any) => {
  if (answers === undefined || answers === null) {
    return false
  }

  const firstKey = Object.keys(answers)[0]?.toString() || ""

  return typeof answers === "object" && firstKey !== "" && answers[firstKey] instanceof Map
}

export const isJSONAnswersMap = (answersJSON: string | null) => {
  const parsedAnswers: any = tryParseMapAnswers(localStorage.getItem("TempSurveyAnswers") || "")

  return areAnswersMap(parsedAnswers)
}

export const stringifyMapAnswers = (answers: Answers<MatrixAnswers>): string => {
  let answersMap = new Map<number, string>()

  for (const key in answers) {
    answersMap.set(Number(key), JSON.stringify(Array.from(answers[key].entries())))
  }

  return JSON.stringify(Array.from(answersMap.entries()))
}
