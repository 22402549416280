import { Box } from "@mui/material"
import React, { FC, ReactNode } from "react"

interface CompactTabPanelProps {
  children: ReactNode
  value: number
  index: number
}

const CompactTabPanel: FC<CompactTabPanelProps> = ({ children, value, index, ...props }) => {
  return (
    <div
      {...props}
      role="tabpanel"
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      <Box px={2} pt={2}>
        {children}
      </Box>
    </div>
  )
}

export default CompactTabPanel
