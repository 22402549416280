import { httpPost } from "../ra-providers/dataProvider"

const INVITATIONS_ENDPOINT = "/Invitations/CreateInstanceInvites"

const sendInstanceInvitations = async (surveyInstanceId: number) => {
  const result = await httpPost(`${INVITATIONS_ENDPOINT}`, {
    surveyInstanceId,
  })
  return result
}

export default {
  sendInstanceInvitations,
}
