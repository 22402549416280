import { SurveyTypeEnum } from "../../../constants"
import { surveyService } from "../../../services"
import { Answers, OcaiAnswerPhaseEnum, OcaiAnswerPhaseStr, OnCompleteProps } from "../../../types"
import { RecordAnswersContext, RecordAnswersContextProvider } from "../context"
import { ocaiStepsBuilder } from "./ocai-survey-steps-builder"

export const ocaiSurveyWizardDetails = {
  convertSurveyToStepsFn: ocaiStepsBuilder.buildSteps,
  provider: RecordAnswersContextProvider,
  context: RecordAnswersContext,
  onComplete: async (
    { surveySlug, userSlug }: OnCompleteProps,
    answers: Answers<Map<string, number>>
  ) => {
    const payload = {
      surveySlug,
      memberSlug: userSlug,
      answers: Object.keys(answers).map((key) => {
        const [questionId, answerPhase] = key.split("-")

        return {
          questionId,
          answerPhase: OcaiAnswerPhaseEnum[answerPhase as OcaiAnswerPhaseStr],
          value: answers[key],
        }
      }),
    }

    return surveyService.submitResponse(payload, SurveyTypeEnum.Ocai)
  },
}
