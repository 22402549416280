import companies from "./companies"
import surveyInstances from "./survey-instances"
import users, { AccountProfile } from "./users"
// import responses from "./responses";
import { ResourceItem } from "../types"
import { Dashboard } from "./dashboard"
import ocaiSurveys from "./instruments/ocai-surveys"
import scaleSurveys from "./instruments/scale-surveys"
import sliderSurveys from "./instruments/slider-surveys"
import textboxSurveys from "./instruments/textbox-surveys"
import teams from "./teams"
import matrixSurveys from "./instruments/matrix-surveys"

export const crudResources: ResourceItem[] = [
  surveyInstances,
  companies,
  //   responses,
  scaleSurveys,
  matrixSurveys,
  sliderSurveys,
  textboxSurveys,
  ocaiSurveys,
  users,
  teams,
]

export { AccountProfile, Dashboard }

export * from "./wizard"
