import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { Theme } from "@mui/system"
import { Box, Divider, Typography } from "@mui/material"
import BulbIcon from "@mui/icons-material/EmojiObjects"
import BuildIcon from "@mui/icons-material/Build"
import BugReportIcon from "@mui/icons-material/BugReport"

const styles = {
  section: {
    paddingBottom: (theme: Theme) => theme.spacing(2),
  },
  description: {
    marginTop: (theme: Theme) => theme.spacing(1),
  },
}

interface SubSection {
  title: string
  icon: React.ReactNode
  items: []
}

const SubSection: React.FC<SubSection> = ({ title, icon, items = [] }) => {
  return items.length > 0 ? (
    <Fragment>
      <Typography variant="body1">
        {icon}
        {title}:
      </Typography>
      <ul>
        {items.map((x, i) => {
          return (
            <li key={i}>
              <Typography variant="body2">{x}</Typography>
            </li>
          )
        })}
      </ul>
    </Fragment>
  ) : null
}

const ReleaseSection = ({
  versionInfo: { v, date, description, newFeatures, improvements, bugFixes },
}) => {
  return (
    <Box sx={styles.section}>
      <Box sx={styles.section}>
        <Typography variant="h5">{`Version ${v}`}</Typography>
        <Divider />
        {date && description && (
          <Typography
            variant="subtitle1"
            sx={styles.description}
          >{`${date} - ${description}`}</Typography>
        )}
      </Box>
      <SubSection title="New" items={newFeatures} icon={<BulbIcon />} />
      <SubSection title="Improvements" items={improvements} icon={<BuildIcon />} />
      <SubSection title="Bug fixes" items={bugFixes} icon={<BugReportIcon />} />
    </Box>
  )
}

ReleaseSection.propTypes = {
  versionInfo: PropTypes.object,
}

export default ReleaseSection
