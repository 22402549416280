import React from "react"
import { ChipField, ChipFieldProps, RaRecord, useRecordContext } from "react-admin"

type CompactChipFieldProps = {
  source: string
  record?: RaRecord
  options: Record<string, any>
} & ChipFieldProps

/**
 * @param {string} source - Name of the property to display.
 * @param {RaRecord} record - The current record to use. Defaults to the RecordContext value.
 * @param {Record<string, any>} options - An object containing options for displaying the desired properties
 * @returns {ReactElement} A ChipField component rendered with specified props.
 */
const CompactChipField: React.FC<CompactChipFieldProps> = ({
  source,
  record,
  options,
  ...rest
}) => {
  const defaultRecord = useRecordContext()

  const currentRecord = record ? record : defaultRecord

  const optionValue = options[currentRecord[source]]

  return <ChipField source={source} record={currentRecord} {...optionValue} {...rest} />
}

export default CompactChipField
