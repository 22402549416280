import React, { FC, useContext } from "react"
import { SurveyLocale } from "../../../../types"
import { NumberAnswersContext } from "../../context"
import { arrayRange } from "./input-common"

export type ScaleInputElementProps = {
  value: number
  index: number
  questionId: number
  onClick: Function
  selectedValue: number
  locale: SurveyLocale
}

type Props = {
  questionId: number
  lowerLimit: number
  upperLimit: number
  InputElement: FC<ScaleInputElementProps>
  locale: SurveyLocale
}

const ScaleInput: FC<Props> = ({ questionId, lowerLimit, upperLimit, InputElement, locale }) => {
  const range = arrayRange(lowerLimit, upperLimit, 1)
  const { updateAnswer, getAnswer } = useContext(NumberAnswersContext)
  const selectedValue = getAnswer(questionId)

  const onClick = (qId: number, value: number) => {
    updateAnswer(qId, value)
  }

  return (
    <>
      {range.map((value, index) => (
        <InputElement
          key={value}
          value={value}
          index={index}
          questionId={questionId}
          onClick={onClick}
          selectedValue={selectedValue}
          locale={locale}
        />
      ))}
    </>
  )
}

export default ScaleInput
