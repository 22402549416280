import React, { FC, PropsWithChildren } from "react"
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles"
import { createTheme } from "@mui/material/styles"
import theme from "../theme"

const ThemeProvider: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const muiTheme = createTheme(theme)

  return <MuiThemeProvider theme={muiTheme}>{children}</MuiThemeProvider>
}

export default ThemeProvider
