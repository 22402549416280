export type OcaiQuestionType = {
  id: number
  answerA: string
  answerB: string
  answerC: string
  answerD: string
}

export enum OcaiAnswerPhaseEnum {
  Current = 1,
  Preferred = 2,
}

export type OcaiAnswerPhaseStr = keyof typeof OcaiAnswerPhaseEnum
