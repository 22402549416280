import { Box, Typography } from "@mui/material"
import React, { FC } from "react"
import { email } from "react-admin"
import { userRolesChoices } from "../../constants"
import { BooleanInput, SelectArrayInput, SimpleForm, TextInput } from "../_design"

const validateEmail = email()

const UsersForm: FC = () => {
  return (
    <SimpleForm>
      <Box display={{ xs: "block", sm: "flex", width: "25%" }}>
        <Typography variant="h6" gutterBottom>
          User Details
        </Typography>
      </Box>

      <Box display={{ xs: "block", sm: "flex", width: "25%" }}>
        <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
          <TextInput source="firstName" fullWidth isRequired />
        </Box>
        <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
          <TextInput source="lastName" fullWidth isRequired />
        </Box>
      </Box>

      <Box display={{ xs: "block", sm: "flex", width: "25%" }}>
        <TextInput label="Email" source="email" validate={validateEmail} isRequired fullWidth />
      </Box>
      <Box display={{ xs: "block", sm: "flex", width: "25%" }}>
        <SelectArrayInput source="roles" choices={userRolesChoices} fullWidth isRequired />
      </Box>
      <Box display={{ xs: "block", sm: "flex", width: "25%" }}>
        <BooleanInput source="isEnabled" label="Is Enabled" fullWidth />
      </Box>
    </SimpleForm>
  )
}

export default UsersForm
