import React from "react"
import { ReferenceArrayInput, SelectArrayInput } from "react-admin"
import { useWatch } from "react-hook-form"
import { resourcesMap } from "../../../constants"

export const MembersRefArrayInput = () => {
  const companyId = useWatch({ name: "companyId" })
  const teamId = useWatch({ name: "teamId" })
  // When a teamId is present in the form, the membersIds field should not be editable
  // because we'll get misaligned between the team members and the membersIds field
  const readOnly = teamId !== undefined

  return (
    <ReferenceArrayInput
      source="membersIds"
      reference={resourcesMap.employees.basePath}
      filter={{ companyId: [companyId ?? -1] }}
    >
      <SelectArrayInput optionText="fullName" sx={{ display: readOnly ? "none" : "block" }} />
    </ReferenceArrayInput>
  )
}
