import React, { FC } from "react"
import { Edit } from "react-admin"
import MatrixSurveyForm from "./MatrixSurveyForm"

const MatrixSurveyEdit: FC = () => {
  return (
    <Edit redirect="list">
      <MatrixSurveyForm />
    </Edit>
  )
}

export default MatrixSurveyEdit
