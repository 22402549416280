import React, { FC } from "react"
import { ArrayInput, SimpleFormIterator, TextInput } from "../_design"

type Props = {}

export const ChapterArrayInput: FC<Props> = ({ children }) => {
  return (
    <ArrayInput source="chapters">
      <SimpleFormIterator fullWidth getItemLabel={(index) => `${index + 1}.`} disableReordering>
        <TextInput source="name" label="Chapter Name" fullWidth isRequired />
        <TextInput source="description" label="Description" multiline fullWidth />
        {children}
      </SimpleFormIterator>
    </ArrayInput>
  )
}
