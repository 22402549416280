import { Typography } from "@mui/material"
import Card from "@mui/material/Card"
import MobileStepper from "@mui/material/MobileStepper"
import React, { FC, useContext } from "react"
import { isSmallScreen } from "../../../utils/common-utils"
import CustomError from "../../participation/common/CustomError"
import { WizardContext } from "../context/WizardContextProvider"
import StepperNavigation from "./StepperNavigation"

const StepContainer: FC = () => {
  const { steps, activeStep, completed, errorMessage, errorOnComplete } = useContext(WizardContext)
  const isMobile = isSmallScreen()

  return (
    <Card sx={{ marginX: [null, null, completed || errorOnComplete ? "20%" : null] }}>
      {completed ? (
        steps.find((step) => step.id === "outro")?.component
      ) : errorOnComplete ? (
        <CustomError message="Unsuccessful submission! Please, reload the page and try again or contact the administrator" />
      ) : (
        <>
          {steps[activeStep].component}
          <Typography color="error" variant="body2" align="center">
            {errorMessage}
          </Typography>
          {!steps[activeStep].hideNav && <StepperNavigation />}
          {isMobile && (
            //@ts-ignore
            <MobileStepper
              variant="dots"
              steps={steps.length}
              position="static"
              sx={{ minWidth: 200 }}
              activeStep={activeStep}
            />
          )}
        </>
      )}
    </Card>
  )
}

export default StepContainer
