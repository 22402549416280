import React, { FC } from "react"
import { Create } from "react-admin"
import TextboxSurveyForm from "./TextboxSurveyForm"

const TextboxSurveyCreate: FC = () => {
  return (
    <Create redirect="list">
      <TextboxSurveyForm />
    </Create>
  )
}

export default TextboxSurveyCreate
