import React, { FC } from "react"
import { Box, Typography } from "@mui/material"
import { ScaleSurveyProps } from "../../../../types"
import ScaleInput, { ScaleInputElementProps } from "./ScaleInput"

const styles = {
  box: {
    border: `1px solid`,
    borderColor: "primary.main",
    display: "flex",
    width: "35px",
    height: "35px",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
      backgroundColor: "primary.light",
      text: "primary.contrastText",
    },
    "&:first-child": {
      borderRadius: "5px 0px 0px 5px",
    },
    "&:last-child": {
      borderRadius: "0px 5px 5px 0px",
    },
  },
  selected: {
    backgroundColor: "primary.main",
  },
  container: {
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
  },
}

const InputElement: FC<ScaleInputElementProps> = ({
  value,
  questionId,
  onClick,
  selectedValue,
}) => (
  <Box
    sx={{
      ...styles.box,
      ...(selectedValue === value && styles.selected),
    }}
    onClick={() => onClick(questionId, value)}
  >
    <Typography variant="body1">{value}</Typography>
  </Box>
)

const NumericScaleInput: FC<ScaleSurveyProps> = (props) => (
  <Box sx={styles.container}>
    <ScaleInput {...props} InputElement={InputElement} />
  </Box>
)

export default NumericScaleInput
