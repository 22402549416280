import React, { FC } from "react"
import SliderSurveyForm from "./SliderSurveyForm"
import { Edit } from "react-admin"

const SliderSurveyEdit: FC = () => {
  return (
    <Edit redirect="list">
      <SliderSurveyForm />
    </Edit>
  )
}

export default SliderSurveyEdit
