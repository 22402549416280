import { SurveyTypeEnum } from "../../../constants/survey.constants"
import { surveyService } from "../../../services"
import { Answers, OnCompleteProps } from "../../../types"
import { NumberAnswersContext, NumberAnswersContextProvider } from "../context"
import { surveyStepsBuilder } from "../survey-steps-builder"

export const sliderSurveyWizardDetails = {
  convertSurveyToStepsFn: surveyStepsBuilder.buildSteps,
  provider: NumberAnswersContextProvider,
  context: NumberAnswersContext,
  onComplete: async ({ surveySlug, userSlug }: OnCompleteProps, answers: Answers<number>) => {
    const payload = {
      surveySlug,
      memberSlug: userSlug,
      answers: Object.keys(answers).map((key) => ({
        questionId: Number(key),
        value: answers[Number(key)],
      })),
    }
    return surveyService.submitResponse(payload, SurveyTypeEnum.Slider)
  },
}
