import React, { FC, createContext, useEffect, useMemo, useState } from "react"
import { Answers } from "../../../types"
import {
  getSurveyAnswersFromLocalStorage,
  saveSurveyAnswersToLocalStorage,
} from "../../../utils/common-utils"

export type GenericAnswersContext<TAnswer> = {
  updateAnswer: (questionId: number | string, value: TAnswer) => void
  getAnswer: (questionId: number | string) => TAnswer
  getAnswers: () => Answers<TAnswer>
  reset: () => void
}

function createAnswersContext<TAnswer = unknown>() {
  return createContext<GenericAnswersContext<TAnswer>>({
    updateAnswer: () => {},
    getAnswer: () => undefined as TAnswer,
    getAnswers: () => ({}),
    reset: () => {},
  })
}

const initialState = {}

function produceContext<TAnswer = unknown>(displayName: string) {
  const Context = createAnswersContext<TAnswer>()
  Context.displayName = displayName

  const AnswersContextProvider: FC = ({ children }) => {
    const [answers, setAnswers] = useState<Answers<TAnswer>>(initialState)

    useEffect(() => {
      if (getSurveyAnswersFromLocalStorage()) {
        setAnswers(getSurveyAnswersFromLocalStorage())
      } else {
        saveSurveyAnswersToLocalStorage(answers)
      }
    }, [])

    const contextObj = useMemo(
      () => ({
        updateAnswer: (questionId: number, value: TAnswer) => {
          setAnswers((old: Answers<TAnswer>) => {
            const newAnswers = {
              ...old,
              [questionId]: value,
            }
            saveSurveyAnswersToLocalStorage(newAnswers)
            return newAnswers
          })
        },
        getAnswer: (questionId: number) => answers[questionId],
        getAnswers: () => answers,
        reset: () => setAnswers(initialState),
      }),
      [answers]
    )

    return <Context.Provider value={contextObj}>{children}</Context.Provider>
  }

  return {
    context: Context,
    provider: AnswersContextProvider,
  }
}

export const answersContextFactory = {
  produceContext,
}
