import React, { FC } from "react"
import { SimpleList } from "react-admin"
import { ArrayField, Datagrid, TextField } from "../_design"
import { ResultsFetchWrapper } from "./ResultsFetchWrapper"

export const TextboxResults: FC = () => {
  return (
    <ResultsFetchWrapper>
      <Datagrid>
        <TextField source="name" />
        <ArrayField source="questions">
          <Datagrid>
            <TextField source="text" />
            <ArrayField source="answers">
              <SimpleList primaryText={(record) => record} />
            </ArrayField>
          </Datagrid>
        </ArrayField>
      </Datagrid>
    </ResultsFetchWrapper>
  )
}
