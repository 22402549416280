import { resourcesMap } from "../../../constants"
import SliderSurveyCreate from "./SliderSurveyCreate"
import SliderSurveyEdit from "./SliderSurveyEdit"
import SliderSurveyList from "./SliderSurveyList"

export default {
  crud: {
    list: SliderSurveyList,
    edit: SliderSurveyEdit,
    create: SliderSurveyCreate,
  },
  ...resourcesMap.sliderSurveys,
}
