import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  RadialLinearScale,
  Title,
  Tooltip,
} from "chart.js"
import React, { FC } from "react"
import { RaRecord, useRecordContext } from "react-admin"
import { PolarArea } from "react-chartjs-2"

ChartJS.register(
  CategoryScale,
  RadialLinearScale,
  ArcElement,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement
)

type PolarAreaChartProps = {
  getLabels: (record: RaRecord) => Array<string>
}

type ChapterAnswers = {
  answers: number[]
  averageScore: number
  chapterId: number
  id: number
  isRequired: boolean
  text: string
}

const PolarAreaChart: FC<PolarAreaChartProps> = ({ getLabels }) => {
  const record = useRecordContext()
  const labels = getLabels(record)
  const allAnswers = record.questions.map((q: ChapterAnswers) =>
    q.answers.reduce((acc, an) => acc + an, 0)
  )

  const colors = [
    "rgba(0, 189, 156, 0.5)", // CP Green
    "rgba(19, 44, 76, 0.9)", // Blue
    "rgba(255, 206, 86, 0.7)", // Yellow
    "#00bd9c", // Green
    "#265a9d", // Lighter Blue
  ]

  const data = {
    labels: labels,
    datasets: [
      {
        data: allAnswers,
        backgroundColor: colors,
        borderWidth: 1,
      },
    ],
  }

  const options = {
    scale: {
      ticks: {
        beginAtZero: true,
      },
    },
    legend: {
      position: "right",
    },
    responsive: true,
    maintainAspectRatio: false,
  }

  return (
    <div style={{ display: "block", height: "300px", width: "600px" }}>
      <PolarArea data={data} options={options} />
    </div>
  )
}

export default PolarAreaChart
