import { Box, Typography } from "@mui/material"
import React, { FC } from "react"

const styles = {
  question: {
    padding: "20px 10px",
  },
  highlightedRow: {
    backgroundColor: "ghostwhite",
  },
  input: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
}

type Props = {
  index: number
  text: string
  isRequired?: boolean
}

export const GenericQuestion: FC<Props> = ({ text, isRequired, index, children }) => {
  return (
    <Box
      sx={{
        ...styles.question,
        ...(index % 2 && styles.highlightedRow),
      }}
    >
      <Typography variant="body1" gutterBottom>
        {text}
        {isRequired ? " *" : null}
      </Typography>
      <Box sx={styles.input}>{children}</Box>
    </Box>
  )
}
