import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { Accordion, AccordionDetails, AccordionSummary, Box } from "@mui/material"
import React, { ReactNode, useState } from "react"
import { useSidebarState } from "react-admin"

const styles = {
  summaryBtn: {
    justifyContent: "flex-start",
    "> div": { flexGrow: 0, my: 0 },
    ":hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
    padding: "6px 16px",
    minHeight: "auto",
  },
}

interface ExpandableMenuItemProps {
  label: string
  icon?: React.ReactElement
  children: ReactNode[]
}

const ExpandableMenuItem: React.FC<ExpandableMenuItemProps> = ({ label, icon, children }) => {
  const [isSidebarOpen] = useSidebarState()
  const [isAccordionOpen, setAccordionOpen] = useState(false)

  const handleAccordionChange = () => {
    setAccordionOpen((prevIsAccordionOpen) => !prevIsAccordionOpen)
  }

  return (
    <Accordion
      expanded={isAccordionOpen}
      onChange={handleAccordionChange}
      elevation={0}
      disableGutters={true}
      sx={{ display: "flex", justifyContent: "flex-start", flexDirection: "column" }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={styles.summaryBtn}>
        {icon && <Box mr={2}>{React.cloneElement(icon, { className: "RaMenuItemLink-icon" })}</Box>}
        {label}
      </AccordionSummary>
      <AccordionDetails sx={{ px: isSidebarOpen ? 1 : 0, py: 0, transition: "0.1s linear" }}>
        <div>{children}</div>
      </AccordionDetails>
    </Accordion>
  )
}

export default ExpandableMenuItem
