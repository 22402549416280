import React, { FC } from "react"
import { FunctionField, useRecordContext } from "react-admin"
import { ArrayField, Datagrid, TextField } from "../_design"
import { ResultsFetchWrapper } from "./ResultsFetchWrapper"
import BubbleChartField from "./charts/bubble-chart-field/BubbleChartField"
import ScatterMultiAxisChart from "./charts/scatter-multiaxis-chart/ScatterMultiAxisChart"

export const SliderResults: FC = () => {
  return (
    <ResultsFetchWrapper>
      <SliderDatagridResults />
    </ResultsFetchWrapper>
  )
}

const SliderDatagridResults = () => {
  const record = useRecordContext()
  return record.resultsFormat === "MultiAxis" ? <MultiAxisResults /> : <SingleLineResults />
}

const MultiAxisResults = () => {
  const record = useRecordContext()

  return (
    <Datagrid bulkActionButtons={false}>
      <FunctionField
        source="chapter"
        render={(partialRecord: any) =>
          `${record["chapters"].findIndex((chapter: any) => chapter.id === partialRecord.id) + 1}. ${partialRecord.name}`
        }
      />
      <ScatterMultiAxisChart />
    </Datagrid>
  )
}

const SingleLineResults = () => {
  const record = useRecordContext()

  return (
    <Datagrid bulkActionButtons={false}>
      <FunctionField
        source="chapter"
        render={(partialRecord: any) =>
          `${record["chapters"].findIndex((chapter: any) => chapter.id === partialRecord.id) + 1}. ${partialRecord.name}`
        }
      />
      <ArrayField source="questions">
        <Datagrid bulkActionButtons={false}>
          <TextField source="text" label="Question" />
          <BubbleChartField />
        </Datagrid>
      </ArrayField>
    </Datagrid>
  )
}
