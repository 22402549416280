import { Box } from "@mui/material"
import FormControlLabel from "@mui/material/FormControlLabel"
import Radio from "@mui/material/Radio"
import React, { FC } from "react"
import { likertLabels } from "../../../../constants/survey.constants"
import { ScaleSurveyProps } from "../../../../types"
import { isSmallScreen } from "../../../../utils/common-utils"
import ScaleInput, { ScaleInputElementProps } from "./ScaleInput"

const isMobile = isSmallScreen()
const styles = {
  container: {
    cursor: "pointer",
    display: "flex",
    flexDirection: isMobile ? "column" : "row",
  },
  questionContainer: {
    flexDirection: isMobile ? "row" : null,
  },
}

const InputElement: FC<ScaleInputElementProps> = ({
  value,
  index,
  questionId,
  onClick,
  selectedValue,
  locale,
}) => {
  return (
    <FormControlLabel
      sx={styles.questionContainer}
      value={value}
      onClick={() => onClick(questionId, value)}
      checked={selectedValue === value}
      control={<Radio />}
      labelPlacement="top"
      label={likertLabels[locale ?? "EN"][index]}
    />
  )
}

const LikertScaleInput: FC<ScaleSurveyProps> = (props) => (
  <Box sx={styles.container}>
    <ScaleInput {...props} InputElement={InputElement} />
  </Box>
)

export default LikertScaleInput
