import { Box, Typography } from "@mui/material"
import React, { FC } from "react"
import { SimpleForm, SimpleFormProps, TextInput } from "react-admin"

type Props = {
  details?: React.ReactNode
} & SimpleFormProps

/**
 * Survey form with built-in required generic inputs allowing extensions
 * @param details - Root level inputs
 * @param children - Chapters structure inputs
 */
export const BaseSurveyForm: FC<Props> = ({ children, details, ...props }) => {
  return (
    <SimpleForm {...props}>
      <Box display={{ xs: "block", sm: "flex", width: "25%" }}>
        <Typography variant="h6" gutterBottom>
          Survey Details
        </Typography>
      </Box>

      {/* Name */}
      <Box display={{ xs: "block", sm: "flex", width: "25%" }}>
        <TextInput source="name" label="Survey Name" fullWidth isRequired />
      </Box>

      {details}

      <Box display={{ xs: "block", sm: "flex", width: "25%" }}>
        <Typography variant="h6" gutterBottom>
          Survey Form
        </Typography>
      </Box>

      {/* Intro & Outro */}
      <Box display={{ xs: "block", sm: "flex", width: "50%" }}>
        <TextInput source="intro" multiline fullWidth />
      </Box>
      <Box display={{ xs: "block", sm: "flex", width: "50%" }}>
        <TextInput source="outro" multiline fullWidth />
      </Box>

      {/* Chapters */}
      <Box display={{ xs: "block", sm: "flex", width: "50%" }}>{children}</Box>
    </SimpleForm>
  )
}
