import { resourcesMap } from "../../constants"
import CompaniesList from "./CompaniesList"
import CompaniesShow from "./CompaniesShow"
import CompaniesEdit from "./CompaniesEdit"
import CompaniesCreate from "./CompaniesCreate"

export default {
  crud: {
    list: CompaniesList,
    show: CompaniesShow,
    edit: CompaniesEdit,
    create: CompaniesCreate,
  },
  ...resourcesMap.companies,
}
