import React, { Fragment, ReactNode, useCallback } from "react"
import PropTypes from "prop-types"
import { isString } from "lodash"
import { Tab, Tabs } from "@mui/material"

function a11yProps(index: number) {
  return {
    id: `compact-tab-${index}`,
    "aria-controls": `compact-tabpanel-${index}`,
  }
}

// labels: array of strings e.g. ["Details", ...]
// labels: array of objects e.g. [{label: "Details", icon: <Icon/>}, ...]

interface CompactTabs {
  labels?: (string | { label: string; icon: string })[]
  activeTab?: number
  color?: string | undefined
  tabProps?: Record<string, any>
  children?: any
}

const CompactTabs: React.FC<CompactTabs> = ({
  labels = [],
  activeTab = 0,
  color = "primary",
  tabProps,
  children,
  ...props
}) => {
  const [value, setValue] = React.useState(activeTab)

  const handleChange = useCallback((event, newValue) => {
    setValue(newValue)
  }, [])

  const validColor = color === "primary" || color === "secondary" ? color : undefined

  return (
    <Fragment>
      <Tabs
        {...props}
        value={value}
        onChange={handleChange}
        aria-label="compact-tabs"
        indicatorColor={validColor}
      >
        {labels.map((x, i) => {
          return isString(x) ? (
            <Tab key={i} label={x} {...a11yProps(i)} {...tabProps} />
          ) : (
            <Tab key={i} label={x.label} icon={x.icon} {...a11yProps(i)} {...tabProps} />
          )
        })}
      </Tabs>
      {React.Children.map(children, (child, i) =>
        React.cloneElement(child, {
          index: i,
          value,
          ...child.props,
        })
      )}
    </Fragment>
  )
}

CompactTabs.propTypes = {
  labels: PropTypes.array,
  activeTab: PropTypes.number,
  color: PropTypes.string,
  tabProps: PropTypes.object,
  children: PropTypes.any,
}

export default CompactTabs
