import React, { FC } from "react"
import { Create } from "../_design"
import UsersForm from "./UsersForm"

const UsersCreate: FC = () => {
  return (
    <Create redirect="list">
      <UsersForm />
    </Create>
  )
}

export default UsersCreate
