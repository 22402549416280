import { Card } from "@mui/material"
import React, { FC } from "react"
import { CompactTabPanel, CompactTabs } from "../../_design/tabs"
import ReleasesTab from "./ReleasesSection/ReleasesTab"
import { versions } from "./ReleasesSection/release-versions.json"
import { useAuthenticated } from "react-admin"

const styles = {
  card: {
    minHeight: "90vh",
  },
}

const About: FC = () => {
  useAuthenticated()

  return (
    <Card sx={styles.card}>
      <CompactTabs activeTab={1} labels={["About", "Releases"]}>
        <CompactTabPanel value={0} index={0}>
          <div>{`Current version: ${versions[0].v}`}</div>
        </CompactTabPanel>
        <ReleasesTab />
      </CompactTabs>
    </Card>
  )
}
export default () => <About />
