import GetAppIcon from "@mui/icons-material/GetApp"
import React, { FC } from "react"
import {
  Button,
  ChipField,
  EditButton,
  ReferenceArrayField,
  ReferenceField,
  SingleFieldList,
  TopToolbar,
  useRecordContext,
} from "react-admin"
import { resourcesMap } from "../../constants"
import { SurveyTypeEnum, surveyConfigs } from "../../constants/survey.constants"
import { SurveyInstanceRecord } from "../../types"
import { parseSurveyMapId } from "../../utils"
import {
  FunctionField,
  Show,
  ShowSplitter,
  SimpleShowLayout,
  Tab,
  TabbedShowLayout,
  TextField,
} from "../_design"
import { SurveyTypeChipField } from "./fields/SurveyTypeChipField"
import MatrixScaleResults from "./MatrixScaleResults"
import { OcaiResults } from "./OcaiResults"
import ScaleResults from "./ScaleResults"
import { SliderResults } from "./SliderResults"
import { TextboxResults } from "./TextboxResults"
import { exportMultipleChartsToPdf } from "./toolbar"
import exportPPTX from "./toolbar/export-pptx/exportPPTX"

const ResultComponentsMap: Record<SurveyTypeEnum, React.ReactElement> = {
  1: <ScaleResults />,
  2: <SliderResults />,
  3: <TextboxResults />,
  4: <MatrixScaleResults />,
  5: <OcaiResults />,
}

const ResultsViewer: FC = () => {
  const record = useRecordContext()
  const { typeId } = parseSurveyMapId(record.surveyMapId)

  return ResultComponentsMap[typeId] ?? <div>Unknown Type</div>
}

const ToolbarActions: FC = () => {
  const record = useRecordContext<SurveyInstanceRecord>()

  return (
    <TopToolbar>
      <EditButton />
      <Button
        onClick={() => exportPPTX(record)}
        variant="outlined"
        label="PPTX"
        startIcon={<GetAppIcon />}
      />
      <Button
        onClick={() => exportMultipleChartsToPdf(record)}
        variant="outlined"
        label="PDF"
        startIcon={<GetAppIcon />}
      />
    </TopToolbar>
  )
}

const SurveyInstanceShow: FC = () => {
  return (
    <Show component="div" actions={<ToolbarActions />}>
      <ShowSplitter
        leftSideProps={{
          md: 12,
        }}
        rightSideProps={{
          md: 12,
        }}
        leftSide={
          <SimpleShowLayout sx={{ display: "block", width: "100%" }}>
            <TextField source="name" label="Survey Name" />
            <FunctionField
              source="type"
              render={(r: SurveyInstanceRecord) => (
                <SurveyTypeChipField surveyMapId={r?.surveyMapId} />
              )}
            />
            <FunctionField
              label="Template"
              render={(r: SurveyInstanceRecord) => {
                const { typeId, surveyId } = parseSurveyMapId(r?.surveyMapId)
                const { resource } = surveyConfigs[typeId]

                return (
                  <ReferenceField
                    source="surveyId"
                    record={{ id: 0, surveyId }}
                    reference={resource}
                  >
                    <TextField source="name" />
                  </ReferenceField>
                )
              }}
            />
            <ReferenceField
              source="companyId"
              reference={resourcesMap.companies.basePath}
              link="show"
            >
              <TextField source="name" />
            </ReferenceField>
            <ReferenceField source="teamId" reference={resourcesMap.teams.basePath} link="show">
              <TextField source="name" />
            </ReferenceField>
            <ReferenceArrayField source="membersIds" reference={resourcesMap.employees.basePath}>
              <SingleFieldList linkType={false}>
                <ChipField source="fullName" size="small" />
              </SingleFieldList>
            </ReferenceArrayField>
          </SimpleShowLayout>
        }
        rightSide={
          <TabbedShowLayout>
            <Tab label="Results">
              <ResultsViewer />
            </Tab>
          </TabbedShowLayout>
        }
      />
    </Show>
  )
}

export default SurveyInstanceShow
