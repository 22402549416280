import { resourcesMap } from "../../../constants"
import { OcaiSurveyCreate } from "./OcaiSurveyCreate"
import { OcaiSurveyEdit } from "./OcaiSurveyEdit"
import { OcaiSurveyList } from "./OcaiSurveyList"

export default {
  crud: {
    list: OcaiSurveyList,
    edit: OcaiSurveyEdit,
    create: OcaiSurveyCreate,
  },
  ...resourcesMap.ocaiSurveys,
}
