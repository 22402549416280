import CardContent from "@mui/material/CardContent"
import React from "react"
import { QuestionProps, Step } from "../../types"

type Props = {
  id: string
  label: string
  content: React.ReactElement | string
  hideNav?: boolean
  validate?: (...args: any[]) => {
    isValid: boolean
    error: string
  }
  questions?: QuestionProps[]
}

export const createSimpleStep = ({
  id,
  label,
  content,
  hideNav = false,
  validate = () => ({ isValid: true, error: "" }),
  questions,
}: Props): Step => ({
  id,
  label,
  hideNav,
  validate,
  questions,
  component: <CardContent style={{ minHeight: "300px" }}>{content}</CardContent>,
})
