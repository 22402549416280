import React, { FC } from "react"
import { Create } from "react-admin"
import SliderSurveyForm from "./SliderSurveyForm"

const SliderSurveyCreate: FC = () => {
  return (
    <Create redirect="list">
      <SliderSurveyForm />
    </Create>
  )
}

export default SliderSurveyCreate
