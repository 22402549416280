import React, { FC } from "react"
import { resourcesMap } from "../../constants"
import { Create, Edit, List } from "../_design"
import SurveyInstanceDatagrid from "./SurveyInstanceDatagrid"
import SurveyInstanceForm from "./SurveyInstanceForm"
import SurveyInstanceShow from "./SurveyInstanceShow"

const SurveyInstanceList: FC = () => {
  return (
    <List sort={{ field: "id", order: "DESC" }} hasCreate={false}>
      <SurveyInstanceDatagrid />
    </List>
  )
}

const SurveyInstanceCreate: FC = () => {
  return (
    <Create redirect="list">
      <SurveyInstanceForm />
    </Create>
  )
}

const SurveyInstanceEdit: FC = () => {
  return (
    <Edit>
      <SurveyInstanceForm />
    </Edit>
  )
}

export default {
  crud: {
    list: SurveyInstanceList,
    show: SurveyInstanceShow,
    edit: SurveyInstanceEdit,
    create: SurveyInstanceCreate,
  },
  ...resourcesMap.surveyInstances,
}
