import React from "react"
import { Chapter, GenericSurvey, Question, Step } from "../../types"
import { MatrixQuestionType } from "../../types/matrix-survey"
import { createSimpleStep } from "../wizard/helpers"
import { Completed, GenericChapter, IntroWithToCAndGDPRCheckbox } from "./common"
import { MatrixQuestion } from "./matrix-survey-parts/MatrixQuestion"
import { ScaleQuestion } from "./scale-survey-parts/ScaleQuestion"
import { SliderQuestion } from "./slider-survey-parts/SliderQuestion"
import { TextboxQuestion } from "./textbox-survey-parts/TextboxQuestion"

const questionTypesMap: Record<number, any> = {
  1: ScaleQuestion,
  2: SliderQuestion,
  3: TextboxQuestion,
  4: MatrixQuestion,
}

const buildSteps = <T extends object>(survey: GenericSurvey, surveyType: number): Step[] => {
  const steps: Step[] = []
  const { intro, outro, chapters, ...rest } = survey

  steps.push(
    createSimpleStep({
      id: "intro",
      label: "Introduction",
      content: <IntroWithToCAndGDPRCheckbox intro={intro ?? "Welcome to the survey!"} />,
    })
  )

  chapters.forEach((ch: Chapter) =>
    steps.push(
      createSimpleStep({
        id: ch.id,
        label: ch.name,
        content: (
          <GenericChapter<T>
            chapter={ch}
            QuestionComponent={questionTypesMap[surveyType]}
            {...(rest as T)}
          />
        ),
        questions: ch.questions,
        validate: (answers, questions = []) => {
          let isValid = false

          switch (surveyType) {
            case 4: // Matrix Survey
              isValid = matrixQuestionValidator(answers, questions as MatrixQuestionType[])
              break
            default:
              isValid = defaultQuestionValidator(answers, questions as Question[])
          }

          return {
            isValid,
            error: isValid ? "" : "Please, answer all questions.",
          }
        },
      })
    )
  )

  steps.push(
    createSimpleStep({
      id: "outro",
      label: "Complete",
      hideNav: true,
      content: <Completed message={outro} />,
    })
  )

  return steps
}

const defaultQuestionValidator = (answers: Record<number, any>, questions: Question[]) => {
  return questions.every((question: Question) => answers[question.id] !== undefined)
}

const matrixQuestionValidator = (answers: Record<number, any>, questions: MatrixQuestionType[]) => {
  return questions.every(
    (question: MatrixQuestionType) => answers[question.id]?.size === question.statements?.length
  )
}

export const surveyStepsBuilder = {
  buildSteps,
}
