import React, { FC } from "react"
import { ArrayField, ReferenceField, useRecordContext } from "react-admin"
import { surveyConfigs } from "../../constants"
import { SurveyInstanceRecord } from "../../types"
import { parseSurveyMapId } from "../../utils"

export const ResultsFetchWrapper: FC = ({ children }) => {
  const record = useRecordContext<SurveyInstanceRecord>()
  const surveyInstanceId: number = record?.id ?? -1
  const { typeId } = parseSurveyMapId(record?.surveyMapId)

  if (!typeId) return <></>

  const { resultsPath } = surveyConfigs[typeId]

  return (
    <ReferenceField
      record={{ id: 0, surveyInstanceId }}
      source="surveyInstanceId"
      reference={`${resultsPath}`}
    >
      <ArrayField source="chapters">{children}</ArrayField>
    </ReferenceField>
  )
}
