import PptxGenJS from "pptxgenjs"

type PlaceholderType = { options: PptxGenJS.PlaceholderProps; text?: string | undefined }

// Title placeholder
export const titlePlaceholder: PlaceholderType = {
  options: { name: "title", type: "title", x: 0, y: 2.75, w: "100%", h: 1.25, align: "center" },
}

// Subtitle placeholder
export const subtitlePlaceholder: PlaceholderType = {
  options: {
    name: "subtitle",
    type: "title",
    x: 1,
    y: 1,
    w: "80%",
    h: 0.6,
    align: "left",
    color: "#143c4c",
  },
}

//Master Slide Body placeholder
export const masterBodyPlaceholder: PlaceholderType = {
  options: {
    name: "masterBody",
    type: "body",
    x: 0,
    y: 4,
    w: "100%",
    h: 4,
    align: "center",
  },
}

// Body placeholder
export const bodyCoords: {
  x: PptxGenJS.Coord
  y: PptxGenJS.Coord
  w: PptxGenJS.Coord
  h: PptxGenJS.Coord
} = {
  x: 0,
  y: 3,
  w: "100%",
  h: 4,
}

export const bodyPlaceholder: PlaceholderType = {
  options: {
    name: "body",
    type: "body",
    x: bodyCoords.x,
    y: bodyCoords.y,
    w: bodyCoords.w,
    h: bodyCoords.h,
    align: "center",
  },
}
