import React, { FC } from "react"
import { Create } from "../../_design"
import { OcaiSurveyForm } from "./OcaiSurveyForm"

export const OcaiSurveyCreate: FC = () => {
  return (
    <Create redirect="list">
      <OcaiSurveyForm />
    </Create>
  )
}
