import SourceIcon from "@mui/icons-material/Source"
import React, { CSSProperties } from "react"
import { Link, Menu, useSidebarState } from "react-admin"
import ExpandableMenuItem from "./ExpandableMenuItem"
import { versions } from "../static-pages/about/ReleasesSection/release-versions.json"

const styles: { [key: string]: CSSProperties } = {
  logo: {
    textAlign: "center",
    marginBottom: "20px",
    marginTop: "10px",
    marginLeft: "10px",
    marginRight: "10px",
    height: "50px",
  },
  logoClosedMenu: {
    width: "35px",
    height: "20px",
    margin: "28px 0px 27px 0px",
  },
  link: {
    textAlign: "center",
    margin: "10px",
  },
}

export const ExtendedMenu = () => {
  const [open] = useSidebarState()

  return (
    <Menu>
      <div style={styles.logo}>
        <img alt="" src="logo.png" style={!open ? styles.logoClosedMenu : styles.logo} />
      </div>
      <Link sx={{ ...styles.link }} to="/about">
        v. {versions[0].v}
      </Link>
      <Menu.ResourceItem name="surveyInstances" />
      <Menu.ResourceItem name="companies" />
      <ExpandableMenuItem label="Templates" icon={<SourceIcon />}>
        <Menu.ResourceItem name="scaleSurveys" />
        <Menu.ResourceItem name="matrixSurveys" />
        <Menu.ResourceItem name="textboxSurveys" />
        <Menu.ResourceItem name="sliderSurveys" />
        <Menu.ResourceItem name="ocaiSurveys" />
      </ExpandableMenuItem>
      <Menu.ResourceItem name="users" />
    </Menu>
  )
}
