import React, { FC } from "react"
import { Create } from "../_design"
import CompaniesForm from "./CompaniesForm"
import { useNotify } from "react-admin"

const CompaniesCreate: FC = () => {
  const notify = useNotify()

  const onError = () => {
    notify(`Error: Incorrect Data Submission`)
  }

  return (
    <Create
      redirect="list"
      mutationOptions={{
        onError: onError,
      }}
    >
      <CompaniesForm />
    </Create>
  )
}

export default CompaniesCreate
