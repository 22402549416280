import html2canvas from "html2canvas"
import PptxGenJS from "pptxgenjs"
import { Chapter } from "../../../../types"
import { detailsProps } from "./exportPPTX"
import { bodyCoords } from "./placeholders"
import { slideMasterTitle } from "./slide-masters"

const imageSizing: {
  type: "contain" | "cover" | "crop"
  w: PptxGenJS.Coord
  h: PptxGenJS.Coord
  x?: PptxGenJS.Coord | undefined
  y?: PptxGenJS.Coord | undefined
} = {
  type: "contain",
  w: bodyCoords.w,
  h: bodyCoords.h,
}

const presentationFont = "Montserrat"

/**
 * Add Text line
 * @example addTextLine("string") // add text line with default breakline
 * @example addTextLine("string", {...options}) // add text line with specified options and default breakline
 * @example addTextLine("string", {}, true) // ad text line without breakline
 */
export const addTextLine = (
  text: string,
  options?: PptxGenJS.TextPropsOptions,
  lastLine: boolean = false
) => {
  return {
    text: text,
    options: { ...options, breakLine: !lastLine },
  }
}

export const addTextAndImageSlide = async (
  pptx: PptxGenJS,
  text: string | undefined,
  canvasImage: string
) => {
  const questionTitleFontSize = 20

  const questionSlide = pptx.addSlide({ masterName: slideMasterTitle })
  questionSlide.addText(text || "", {
    placeholder: "subtitle",
    fontSize: questionTitleFontSize,
    fontFace: presentationFont,
    fill: "#e4fbf1",
  })
  questionSlide.addImage({ placeholder: "body", data: canvasImage, sizing: imageSizing })
}

const chapterTitleFontSize = 20
const chapterBodyFontSize = 52

export const addChapterSlide = async (pptx: PptxGenJS, chapter: Chapter) => {
  const chapterSlide = pptx.addSlide({ masterName: slideMasterTitle })
  chapterSlide.addText("Chapter", { placeholder: "title", fontSize: chapterTitleFontSize })
  chapterSlide.addText(chapter.name, { placeholder: "body", fontSize: chapterBodyFontSize })
}

export const doesChapterHaveQuestionsSection = (chapter: Chapter): boolean => {
  const questionText = chapter?.questions[0]?.text
  const questionTdXPath = `//td[contains(., '${questionText}')]`
  const questionTdHTMLElement = document.evaluate(
    questionTdXPath,
    document,
    null,
    XPathResult.FIRST_ORDERED_NODE_TYPE,
    null
  ).singleNodeValue

  return !!questionTdHTMLElement
}

export const addCanvasResultsSlides = async (
  pptx: PptxGenJS,
  chapter: Chapter,
  chapterIndex: number
) => {
  addChapterSlide(pptx, chapter)

  const canvasImages = await getChapterCanvasImagesBase64(`${chapterIndex}. ${chapter.name}`)

  if (doesChapterHaveQuestionsSection(chapter)) {
    const numberOfQuestions = chapter.questions.length
    for (let i = 0; i < numberOfQuestions; i++) {
      addTextAndImageSlide(pptx, chapter?.questions[i]?.text, canvasImages[i])
    }
  } else {
    addTextAndImageSlide(pptx, chapter?.questions[0]?.text, canvasImages[0])
  }
}

export const addTextboxResultsSlides = async (pptx: PptxGenJS, chapter: Chapter) => {
  addChapterSlide(pptx, chapter)

  const numberOfQuestions = chapter.questions.length
  for (let i = 0; i < numberOfQuestions; i++) {
    const questionSlide = pptx.addSlide({ masterName: slideMasterTitle })
    questionSlide.addText(chapter?.questions[i]?.text || "", {
      placeholder: "title",
      fontSize: chapterTitleFontSize,
      fontFace: presentationFont,
    })

    const answersLines: PptxGenJS.TextProps[] = []
    const answerLineFontSize = 12
    chapter?.questions[i]?.answers?.map((answer: string) =>
      answersLines.push(addTextLine(answer, { fontSize: answerLineFontSize }))
    )
    questionSlide.addText(answersLines, {
      placeholder: "body",
      fontSize: chapterBodyFontSize,
      fontFace: presentationFont,
    })
  }
}

export const addIntroductionSlide = (pptx: PptxGenJS, details: detailsProps) => {
  const introductionTitleFontSize = 42
  const introductionBodyFontSize = 15
  const introductionTextColor = "#00d794"

  const introductionSlide = pptx.addSlide({ masterName: slideMasterTitle })
  introductionSlide.background = { path: "public/images/main_slide_background.png" }

  introductionSlide.addText(details.surveyName, {
    placeholder: "title",
    fontSize: introductionTitleFontSize,
    fontFace: presentationFont,
    color: introductionTextColor,
  })
  introductionSlide.addText(
    [
      addTextLine(`Company: ${details.companyName}`, { fontSize: introductionBodyFontSize }),
      addTextLine(`Team: ${details.teamName}`, { fontSize: introductionBodyFontSize }),
      addTextLine(`Survey Type: ${details.surveyType}`, { fontSize: introductionBodyFontSize }),
      addTextLine(`Start Date: ${details.startDate}`, { fontSize: introductionBodyFontSize }),
      addTextLine(`End Date: ${details.endDate}`, { fontSize: introductionBodyFontSize }, true),
    ],
    { placeholder: "masterBody", fontFace: presentationFont, color: introductionTextColor }
  )
}

export const addOutroductionSlide = async (pptx: PptxGenJS) => {
  const outroductionTitleFontSize = 52

  const outroductionSlide = pptx.addSlide({ masterName: slideMasterTitle })
  outroductionSlide.addText("End", {
    placeholder: "title",
    fontSize: outroductionTitleFontSize,
    fontFace: presentationFont,
  })
}

export const getChapterCanvasImagesBase64 = async (chapterName: string): Promise<string[]> => {
  const chapterXPath = `//td[contains(., '${chapterName}')]`
  const chapterHTMLElement = document.evaluate(
    chapterXPath,
    document,
    null,
    XPathResult.FIRST_ORDERED_NODE_TYPE,
    null
  ).singleNodeValue

  const parent = chapterHTMLElement?.parentElement

  const canvases = Array.from(parent?.querySelectorAll("canvas") || [])

  const canvasImagesBase64 = await Promise.all(
    canvases.map(async (canvas) => {
      const divWrapper = canvas.closest("div") || document.createElement("div")
      const imageDataUrl = await html2canvas(divWrapper).then((canvas) =>
        canvas.toDataURL("image/png")
      )
      return imageDataUrl
    })
  )

  return canvasImagesBase64
}
