import React, { FC } from "react"
import { Create, Edit, Identifier, RaRecord } from "react-admin"
import { resourcesMap } from "../../constants"
import { TeamsForm } from "./TeamsForm"
import TeamsShow from "./TeamsShow"

// Redirect to company's teams tab
const redirect = (_: any, __: any, data: Partial<RaRecord<Identifier>> | undefined) =>
  `${resourcesMap.companies.basePath}/${data?.companyId}/show/1`

const TeamsCreate: FC = () => {
  return (
    <Create redirect={redirect}>
      <TeamsForm />
    </Create>
  )
}

const TeamsEdit: FC = () => {
  return (
    <Edit redirect={redirect}>
      <TeamsForm />
    </Edit>
  )
}

export default {
  crud: {
    show: TeamsShow,
    create: TeamsCreate,
    edit: TeamsEdit,
  },
  ...resourcesMap.teams,
}
