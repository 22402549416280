import React, { FC } from "react"
import { Create } from "react-admin"
import MatrixSurveyForm from "./MatrixSurveyForm"

const MatrixSurveyCreate: FC = () => {
  return (
    <Create redirect="list">
      <MatrixSurveyForm />
    </Create>
  )
}

export default MatrixSurveyCreate
