import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import { Box } from "@mui/material"
import Typography from "@mui/material/Typography"
import React, { FC } from "react"
import { useTranslate } from "react-admin"

const styles = {
  container: {
    alignItems: "center",
    width: "100%",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",

    "& p": {
      margin: "20px 0px",
    },
  },
}
type Props = { message?: string }

export const Completed: FC<Props> = ({ message }) => {
  // const { handleReset } = useContext(WizardContext)
  const translate = useTranslate()

  return (
    <Box display="flex" sx={styles.container}>
      <CheckCircleIcon color="primary" fontSize="large" />
      {<Typography>{message ?? translate("completedSurvey.thankYou")}</Typography>}
      {/* <Button onClick={handleReset}>Reset</Button> */}
    </Box>
  )
}
