import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from "chart.js"
import React, { FC } from "react"
import { useRecordContext } from "react-admin"
import { Bar } from "react-chartjs-2"
import { getScaleAnswersLabels } from "../../../../utils"

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement
)

type StackedBarChartProps = {
  getLabels: (record: Record<string, any>) => Array<string>
  fullRecord: any
}

type Dataset = {
  label: string
  data: { x: string; y: number }[]
  backgroundColor: string
  borderWidth: number
}[]

const options = {
  scales: {
    x: { stacked: true },
    y: { stacked: true },
  },
}

const colors = [
  "rgba(0, 189, 156, 0.5)", // CP Green
  "rgba(19, 44, 76, 0.9)", // Blue
  "rgba(255, 206, 86, 0.7)", // Yellow
  "#00bd9c", // Green
  "#265a9d", // Lighter Blue
]

type ResultMap = Record<string, Record<number, number>>

const StackedBarChart: FC<StackedBarChartProps> = ({ getLabels, fullRecord }) => {
  const record = useRecordContext<{ questions: { text: string; answers: number[] }[] }>()
  const labels = getLabels(record)

  const labelsArray = getScaleAnswersLabels(fullRecord) as (number | string)[]
  const combinedResults = record.questions.reduce((acc: ResultMap, q) => {
    const answersDistribution = labelsArray.reduce((acc: Record<string, number>, l) => {
      acc[l] = 0
      return acc
    }, {})
    q.answers.forEach((answer: number) => {
      if (fullRecord.scaleType === "Likert") {
        const label = labelsArray[answer - 1]
        answersDistribution[label]++
      } else {
        answersDistribution[answer]++
      }
    })

    acc[q.text] = answersDistribution
    return acc
  }, {})

  const datasets: Dataset = []

  labelsArray.forEach((label: any, i) => {
    datasets.push({
      label: label.toString(),
      data: Object.keys(combinedResults).map((qText) => ({
        x: qText,
        y: combinedResults[qText][label],
      })),
      backgroundColor: colors[i],
      borderWidth: 1,
    })
  })

  return (
    <div style={{ display: "block", height: "400px", width: "800px" }}>
      <Bar
        data={{
          labels: labels,
          datasets: datasets,
        }}
        options={options}
      />
    </div>
  )
}

export default StackedBarChart
