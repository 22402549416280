import React, { FC, PropsWithChildren } from "react"
import { SurveyTypeEnum } from "../../../constants/survey.constants"
import { surveyService } from "../../../services"
import { Answers, GenericSurvey, OnCompleteProps, Step } from "../../../types"
import {
  GenericAnswersContext,
  NumberAnswersContext,
  NumberAnswersContextProvider,
} from "../context"
import { surveyStepsBuilder } from "../survey-steps-builder"

export type ScaleSurveyWizardDetailsProps = {
  convertSurveyToStepsFn: (survey: GenericSurvey, surveyType: number) => Step[]
  provider: FC<PropsWithChildren<unknown>>
  context: React.Context<GenericAnswersContext<number>>
  onComplete: (participationSlugs: OnCompleteProps, answers: Answers<number>) => void
}

export const scaleSurveyWizardDetails: ScaleSurveyWizardDetailsProps = {
  convertSurveyToStepsFn: surveyStepsBuilder.buildSteps,
  provider: NumberAnswersContextProvider,
  context: NumberAnswersContext,
  onComplete: async ({ surveySlug, userSlug }, answers) => {
    const payload = {
      surveySlug,
      memberSlug: userSlug,
      answers: Object.keys(answers).map((key) => ({
        questionId: Number(key),
        value: answers[Number(key)],
      })),
    }

    return surveyService.submitResponse(payload, SurveyTypeEnum.Scale)
  },
}
