import { Box } from "@mui/material"
import React, { FC } from "react"
import { SelectInput } from "react-admin"
import { sliderResultsFormat } from "../../../constants/survey.constants"
import { ArrayInput, BooleanInput, NumberInput, SimpleFormIterator, TextInput } from "../../_design"
import { BaseSurveyForm } from "../BaseSurveyForm"
import { ChapterArrayInput } from "../ChapterArrayInput"

const labelStyle = { flexBasis: "30%" }

const SliderSurveyForm: FC = () => {
  return (
    <BaseSurveyForm
      warnWhenUnsavedChanges
      details={
        <Box display={{ xs: "block", sm: "flex", width: "25%" }}>
          <SelectInput source="resultsFormat" choices={sliderResultsFormat} isRequired />
        </Box>
      }
    >
      <ChapterArrayInput>
        <ArrayInput source="questions" label="Questions">
          <SimpleFormIterator inline getItemLabel={(index) => `${index + 1}.`} disableReordering>
            <TextInput source="text" label="Text" fullWidth multiline isRequired />
            <TextInput source="leftLabel" label="Left Label" isRequired sx={labelStyle} />
            <TextInput source="centerLabel" label="Center Label" isRequired sx={labelStyle} />
            <TextInput source="rightLabel" label="Right Label" isRequired sx={labelStyle} />
            <BooleanInput
              source="isRequired"
              label="Required"
              defaultValue={true}
              sx={labelStyle}
            />
            <NumberInput
              source="startingPoint"
              label="Starting Point"
              defaultValue={0}
              sx={labelStyle}
            />
          </SimpleFormIterator>
        </ArrayInput>
      </ChapterArrayInput>
    </BaseSurveyForm>
  )
}

export default SliderSurveyForm
