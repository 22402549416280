import CalendarViewMonthIcon from "@mui/icons-material/CalendarViewMonth"
import LinearScaleIcon from "@mui/icons-material/LinearScale"
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer"
import TextFieldsIcon from "@mui/icons-material/TextFields"
import TuneIcon from "@mui/icons-material/Tune"
import React, { FC } from "react"
import { surveyConfigs } from "../../../constants/survey.constants"
import { parseSurveyMapId } from "../../../utils"
import CompactChipField from "../compact-chip-field/CompactChipField"

const chipFieldOptions: Record<string, any> = {
  Scale: {
    icon: <LinearScaleIcon />,
    color: "success",
  },
  Slider: {
    icon: <TuneIcon />,
    color: "info",
  },
  Textbox: {
    icon: <TextFieldsIcon />,
    color: "warning",
  },
  Matrix: {
    icon: <CalendarViewMonthIcon />,
    color: "amber",
  },
  Ocai: {
    icon: <QuestionAnswerIcon />,
    color: "purple",
  },
}

type Props = { surveyMapId: string }

export const SurveyTypeChipField: FC<Props> = ({ surveyMapId }) => {
  const { typeId, surveyId } = parseSurveyMapId(surveyMapId)
  const { shortName } = surveyConfigs[typeId]

  return (
    <CompactChipField
      sx={{ width: "95px", marginRight: 1 }}
      source="type"
      record={{
        id: surveyId,
        type: shortName,
      }}
      options={chipFieldOptions}
    />
  )
}
