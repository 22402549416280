/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 */
type Image = {
  title: string
  rawFile: File
}

type Params = {
  data: any
}

export const convertFileToBase64 = (file: Image): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()

    // Assign resolve
    reader.onload = () => {
      if (typeof reader.result === "string") {
        resolve(reader.result)
      } else {
        reject(new Error("Failed to convert file to base64."))
      }
    }

    // Assign reject
    reader.onerror = (error) => reject(error)

    // Try reading the file
    reader.readAsDataURL(file.rawFile)
  })

// TODO: restDataProvider

const transformImagesAndRespond = (
  action: "create" | "update",
  restDataProvider: any,
  resource: string,
  params: { data: { images: Image[] } }
): Promise<any> => {
  const newImages = params.data.images.filter((p) => p.rawFile instanceof File)
  const formerImages = params.data.images.filter((p) => !(p.rawFile instanceof File))

  return Promise.all(newImages.map(convertFileToBase64))
    .then((base64Pictures) => transformImages(base64Pictures, params))
    .then((transformedNewImages) => {
      return action === "create"
        ? restDataProvider.create(
            resource,
            transformedImagesReducer(transformedNewImages, formerImages, params)
          )
        : restDataProvider.update(
            resource,
            transformedImagesReducer(transformedNewImages, formerImages, params)
          )
    })
}

const transformImages = (
  base64Pictures: string[],
  params: Params
): { base64String: string; title: string }[] => {
  // Transform converted images to a new object
  return base64Pictures.map((picture64, i) => ({
    base64String: picture64,
    title: `${params.data.images[i].title}`,
  }))
}

const transformedImagesReducer = (
  transformedNewImages: { base64String: string; title: string }[],
  formerImages: Image[],
  params: Params
): Params => ({
  ...params,
  data: {
    ...params.data,
    // Add images to the initial params
    images: [...transformedNewImages, ...formerImages],
  },
})

export default transformImagesAndRespond
