import { resourcesMap } from "../../../constants"
import MatrixSurveyCreate from "./MatrixSurveyCreate"
import MatrixSurveyEdit from "./MatrixSurveyEdit"
import MatrixSurveyList from "./MatrixSurveyList"

export default {
  crud: {
    list: MatrixSurveyList,
    edit: MatrixSurveyEdit,
    create: MatrixSurveyCreate,
  },
  ...resourcesMap.matrixSurveys,
}
