import { resourcesMap } from "../../../constants"
import TextboxSurveyCreate from "./TextboxSurveyCreate"
import TextboxSurveyEdit from "./TextboxSurveyEdit"
import TextboxSurveyList from "./TextboxSurveyList"

export default {
  crud: {
    list: TextboxSurveyList,
    edit: TextboxSurveyEdit,
    create: TextboxSurveyCreate,
  },
  ...resourcesMap.textboxSurveys,
}
