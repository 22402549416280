import React, { FC } from "react"
import { Edit } from "react-admin"
import TextboxSurveyForm from "./TextboxSurveyForm"

const TextboxSurveyEdit: FC = () => {
  return (
    <Edit>
      <TextboxSurveyForm />
    </Edit>
  )
}

export default TextboxSurveyEdit
