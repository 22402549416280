import Box from "@mui/material/Box"
import CircularProgress from "@mui/material/CircularProgress"
import Typography from "@mui/material/Typography"
import React from "react"

type Props = {
  value: number
}

function CircularProgressWithLabel({ value }: Props) {
  const minValue: number = -100
  const isPositive: boolean = value > 0

  return (
    <Box position="relative" display="inline-flex" marginLeft={2}>
      <CircularProgress
        variant="determinate"
        value={value > minValue ? value : minValue}
        color={isPositive ? "primary" : "error"}
      />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" component="div" color="textSecondary">
          {`${Math.round(value)}`}
        </Typography>
      </Box>
    </Box>
  )
}

export default CircularProgressWithLabel
