import { RaRecord, useRecordContext } from "react-admin"
import React from "react"
import { Bubble } from "react-chartjs-2"
import { ChartOptions, TooltipItem } from "chart.js"

const getOptions = (record: RaRecord): ChartOptions<"bubble"> => ({
  responsive: true,
  plugins: {
    legend: {
      display: false,
      position: "top" as const,
    },
    title: {
      display: true,
      text: record.text,
      align: "start",
      font: { size: 14 },
      padding: 20,
    },
    tooltip: {
      callbacks: {
        label: (item: TooltipItem<"bubble">) => item.parsed.x.toString(),
      },
    },
  },
  scales: {
    x: {
      ticks: {
        stepSize: 5,
      },
      min: 1,
      max: 100,
    },
    y: {
      ticks: {
        stepSize: 1,
      },
      min: 0,
      max: 3,
    },
  },
})

const BubbleChartField = () => {
  const record = useRecordContext()

  if (record.answers === null) {
    return <>No Results</>
  }

  const valueCount: Record<number, number> = {}

  const answers = record.answers.map((value: number) => {
    valueCount[value] = (valueCount[value] || 0) + 1
    return { x: value, y: 1 + 0.2 * (valueCount[value] - 1), r: 6 }
  })

  const data = {
    datasets: [
      {
        labels: Array.from({ length: 100 }, (v, i) => i),
        data: answers,
        borderColor: "#00bd9c",
        backgroundColor: "rgba(0, 189, 156, 0.5)",
      },
    ],
  }

  return (
    <div style={{ display: "block", height: "200px", width: "400px" }}>
      <Bubble data={data} options={getOptions(record)} />
    </div>
  )
}

export default BubbleChartField
