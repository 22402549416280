import decodeJwt from "jwt-decode"
import {
  ForgotPasswordPath,
  LoginPath,
  ResetPasswordPath,
  SignUpPath,
} from "../constants/api.constants"
import { userRolesChoices } from "../constants/user-roles.constants"

const userlocalStorageKey: string = "user"
const userPermissionsLocalStorageKey: string = "permissions"
const LOGIN_PATH: string = "/login"

type User = {
  token: string
}

// TODO: Look at the signupException

const SignUpException = (title: string, errors: ErrorEvent[]) => {
  // this.title = title
  // this.errors = errors
}

type SignUpForm = {
  username: string
  password: string
}

const signUpHandler = (signUpForm: SignUpForm) => {
  const request = new Request(SignUpPath, {
    method: "POST",
    body: JSON.stringify(signUpForm),
    headers: new Headers({ "Content-Type": "application/json" }),
  })

  return fetch(request)
    .then((response) => {
      return response.json()
    })
    .then((json) => {
      if (json.status < 200 || json.status >= 300) {
        throw SignUpException(json.title, json.errors)
      }

      return json
    })
}

const loginHandler = ({ email, password }: { email: string; password: string }): Promise<void> => {
  const request = new Request(LoginPath, {
    method: "POST",
    body: JSON.stringify({ email, password }),
    headers: new Headers({ "Content-Type": "application/json" }),
  })

  return fetch(request)
    .then((response) => {
      if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText)
      }

      return response.json()
    })
    .then((user: User) => {
      localStorage.setItem(userlocalStorageKey, JSON.stringify(user))
      const decodedToken: any = decodeJwt(user.token)

      const permissionsArr = decodedToken.permissions.split(",")

      const permissionsObj: Record<string, boolean> = {}

      userRolesChoices.forEach((role) => {
        permissionsObj[role.id] = permissionsArr.includes(role.id)
      })

      localStorage.setItem(userPermissionsLocalStorageKey, JSON.stringify(permissionsObj))
    })
}

const logoutHandler = (): Promise<void> => {
  localStorage.removeItem(userlocalStorageKey)
  localStorage.removeItem(userPermissionsLocalStorageKey)

  return Promise.resolve()
}

const checkErrorHandler = (error: { status: number }): Promise<void> => {
  const status = error?.status

  if (status === 401) {
    localStorage.removeItem(userlocalStorageKey)
    return Promise.reject() // results in redirect
  } else if (status === 403) {
    // window.location.replace("/");
    return Promise.resolve()
  }

  return Promise.resolve()
}

const forgotPasswordHandler = (email: string): Promise<Response> => {
  const request = new Request(ForgotPasswordPath, {
    method: "POST",
    body: JSON.stringify({ email }),
    headers: new Headers({ "Content-Type": "application/json" }),
  })

  return fetch(request)
    .then((response) => {
      return response
    })
    .then((json) => {
      if (json.status < 200 || json.status >= 300) {
        throw new Error(json.statusText)
      }

      return json
    })
}

const resetPasswordHandler = (values: Record<string, string>): Promise<Response> => {
  const request = new Request(ResetPasswordPath, {
    method: "POST",
    body: JSON.stringify({ ...values }),
    headers: new Headers({ "Content-Type": "application/json" }),
  })

  return fetch(request)
    .then((response) => {
      return response
    })
    .then((json) => {
      if (json.status < 200 || json.status >= 300) {
        throw new Error(json.statusText)
      }

      return json
    })
}

const checkAuthHandler = (): Promise<void> => {
  return localStorage.getItem(userlocalStorageKey)
    ? Promise.resolve()
    : Promise.reject({ redirectTo: LOGIN_PATH })
}

const getPermissionsHandler = (): Promise<Record<string, boolean>> => {
  const permissions = localStorage.getItem(userPermissionsLocalStorageKey)
  return permissions ? Promise.resolve(JSON.parse(permissions)) : Promise.reject()
}

const getCurrentUser = (): User | null => {
  const user = JSON.parse(localStorage.getItem(userlocalStorageKey) || "null")
  const decodedToken = user && decodeJwt(user.token)

  return {
    ...user,
    ...decodedToken,
  }
}

export default {
  login: loginHandler,
  logout: logoutHandler,
  checkAuth: checkAuthHandler,
  checkError: checkErrorHandler,
  getPermissions: getPermissionsHandler,
  signUp: signUpHandler,
  getCurrentUser: getCurrentUser,
  forgotPassword: forgotPasswordHandler,
  resetPassword: resetPasswordHandler,
}
