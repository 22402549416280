import { Typography } from "@mui/material"
import TextField from "@mui/material/TextField"
import React, { FC, useContext } from "react"
import { Question } from "../../../types/chapter"
import { isSmallScreen } from "../../../utils/common-utils"
import { StringAnswersContext } from "../context/StringAnswersContext"
import { GenericQuestionProps } from "../types"

export const TextboxQuestion: FC<GenericQuestionProps<Question>> = ({
  question: { id, text, isRequired },
  index,
}) => {
  const { updateAnswer, getAnswer } = useContext(StringAnswersContext)
  const currentValue = getAnswer(id)
  const isMobile = isSmallScreen()
  const onChange = (qId: number, value: string) => {
    updateAnswer(qId, value)
  }

  return (
    <div
      style={{
        padding: "20px 10px",
        marginTop: isMobile ? "20px" : undefined,
        backgroundColor: index % 2 ? "ghostwhite" : undefined,
      }}
    >
      <Typography variant="body1" gutterBottom>
        {text}
        {isRequired ? " *" : null}
      </Typography>
      <span>
        <TextField
          fullWidth
          multiline
          key={id}
          defaultValue={currentValue}
          type="text"
          onChange={(event) => {
            onChange(id, event.target.value)
          }}
        />
      </span>
    </div>
  )
}
