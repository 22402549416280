type RoleProps = {
  id: string
  name: string
}

type UserRolesProps = {
  Admin: RoleProps
  SuperUser: RoleProps
  BasicUser: RoleProps
}

export const userRoles: UserRolesProps = {
  Admin: { id: "Admin", name: "Admin" },
  SuperUser: { id: "SuperUser", name: "SuperUser" },
  BasicUser: { id: "BasicUser", name: "BasicUser" },
}

export const userRolesChoices: RoleProps[] = [
  userRoles.Admin,
  userRoles.SuperUser,
  userRoles.BasicUser,
]

const superUsersRoles: string[] = [userRoles.Admin.id, userRoles.SuperUser.id]

const allUserRoles: string[] = [...userRolesChoices.map((x) => x.id)]

type UserRolesGroupsProps = {
  superUsers: string[]
  everyone: string[]
  management: string[]
}

export const userRolesGroups: UserRolesGroupsProps = {
  superUsers: superUsersRoles,
  everyone: allUserRoles,
  management: [],
}
