import { SurveyTypeEnum, surveyConfigs } from "../constants/survey.constants"
import { httpPost } from "../ra-providers/dataProvider"

const submitResponse = async <T>(payload: T, surveyType: SurveyTypeEnum) => {
  const { shortName } = surveyConfigs[surveyType]

  return await httpPost(`/${shortName}Responses`, payload)
}

export default { submitResponse }
