import React from "react"
import { useAuthProvider, useAuthenticated } from "react-admin"
import Card from "@mui/material/Card"
import CardHeader from "@mui/material/CardHeader"

const Dashboard = (): JSX.Element => {
  useAuthenticated()

  const authProvider = useAuthProvider()
  const currentUser = authProvider.getCurrentUser()
  return (
    <Card>
      <CardHeader title={`Welcome, ${currentUser.firstName} ${currentUser.lastName}!`} />
    </Card>
  )
}

export default Dashboard
