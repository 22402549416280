import React, { FC } from "react"
import { Line } from "react-chartjs-2"
import { useRecordContext } from "react-admin"
import { ChartOptions } from "chart.js"
import { Box } from "@mui/material"
import { Chart, Filler } from "chart.js"

Chart.register(Filler)

interface Answer {
  averageScore: number
  text: string
}

interface Statement {
  matrixScaleQuestionId: number
  text: string
  id: number
}

interface MatrixScalResult {
  answers: Answer[]
  statements: Statement[]
  chapterId: number
  text: string
  isRequired: boolean
  id: number
}

type LineChartFieldProps = {
  yColumnsLabels: Map<number, string>
}

const getOptions = (
  yColumnsLabels: Map<number, string>,
  yColumnsLabelsTicks: number[]
): ChartOptions<"line"> => ({
  responsive: true,
  scales: {
    y: {
      ticks: {
        callback: function (value, index, ticks) {
          return yColumnsLabels.get(Number(value))
        },
        autoSkip: false,
      },
      min: yColumnsLabelsTicks[yColumnsLabelsTicks.length - 1],
      max: yColumnsLabelsTicks[0],
      afterBuildTicks: function (scale) {
        scale.ticks = yColumnsLabelsTicks.map((tick) => ({ value: tick }))
        return
      },
      beforeUpdate: function (oScale) {
        return
      },
      offset: true,
    },
  },
})

const LineChartField: FC<LineChartFieldProps> = ({ yColumnsLabels }) => {
  const record = useRecordContext<MatrixScalResult>()

  if (record.answers === null) {
    return <>No Results</>
  }

  const { answers, text } = record
  const xStatements: string[] = answers.map((asnwer) => asnwer.text)
  const xAverageScores: number[] = answers.map((answer) => answer.averageScore)

  const yColumnsLabelsTicks = Array.from(yColumnsLabels.keys())

  let min = Math.min.apply(Math, yColumnsLabelsTicks)
  let max = Math.max.apply(Math, yColumnsLabelsTicks)

  const data = {
    labels: xStatements,
    datasets: [
      {
        label: text,
        data: xAverageScores,
        fill: true,
        borderColor: "rgb(75, 192, 192)",
        min: min,
        max: max,
      },
    ],
  }

  return (
    <Box style={{ display: "block", height: "400px", maxWidth: "800px" }}>
      <Line data={data} options={getOptions(yColumnsLabels, yColumnsLabelsTicks)} />
    </Box>
  )
}

export default LineChartField
