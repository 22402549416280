import React, { FC } from "react"
import { Box } from "@mui/material"

const styles = {
  root: {
    background: "url(./images/home-page-bg.svg)",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    width: "100vw",
    height: "100vh",
    margin: "-8px",
  },
}

const HomePage: FC = () => <Box sx={styles.root}></Box>

export default () => <HomePage />
