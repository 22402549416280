import React, { FC } from "react"
import SliderScaleInput from "./Inputs/SliderScaleInput"
import { SliderExtraProps, SliderQuestionType } from "../../../types"
import { GenericQuestionProps } from "../types"
import { GenericQuestion } from "../common"

export const SliderQuestion: FC<GenericQuestionProps<SliderQuestionType> & SliderExtraProps> = ({
  question: { id, text, isRequired, rightLabel, leftLabel, startingPoint, centerLabel },
  index,
  resultsFormat,
  ...rest
}) => {
  return (
    <GenericQuestion text={text} isRequired={isRequired} index={index}>
      <SliderScaleInput
        {...rest}
        questionId={id}
        leftLabel={leftLabel}
        rightLabel={rightLabel}
        centerLabel={centerLabel}
        startingPoint={startingPoint}
      />
    </GenericQuestion>
  )
}
