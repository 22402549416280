import { amber, indigo } from "@mui/material/colors"
import merge from "lodash/merge"
import { defaultTheme } from "react-admin"

const themeColors = {
  primary: {
    bright: "#bff7df",
    light: "#3ee98d",
    main: "#00d694",
    dark: "#00bd9c",
  },
  purple: indigo,
  amber,
  secondary: "#132c4c",
  disabled: "#ededed",
}

const myTheme = merge(
  defaultTheme,
  {},
  {
    palette: {
      primary: { main: themeColors.primary.dark },
      secondary: { main: themeColors.secondary },
      // error: red,
      contrastThreshold: 3,
      tonalOffset: 0.2,
      purple: {
        light: themeColors.purple[300],
        main: themeColors.purple[500],
        dark: themeColors.purple[700],
        contrastText: "#fff",
      },
      amber: {
        light: themeColors.amber[300],
        main: themeColors.amber[500],
        dark: themeColors.amber[700],
        contrastText: "#8b0000",
      },
    },
    typography: {
      // Use the system font instead of the default Roboto font.
      fontFamily: ["-apple-system", "BlinkMacSystemFont", '"Segoe UI"', "Arial", "sans-serif"].join(
        ","
      ),
    },
    components: {
      RaMenuItemLink: {
        styleOverrides: {
          root: {
            color: themeColors.secondary,
            textDecoration: "none",
            "&:hover": {
              color: themeColors.secondary,
            },
            "&.RaMenuItemLink-active": {
              color: "white",
              fontWeight: "bold",
              backgroundColor: themeColors.secondary,
              "& svg": {
                color: "white",
              },
            },
            "& .RaMenuItemLink-icon": {
              color: themeColors.secondary,
              minWidth: "40px",
            },
          },
        },
      },
      MuiButton: {
        // override the styles of all instances of this component
        containedPrimary: {
          color: "white",
        },
      },
    },
  }
)

export default myTheme
