import React from "react"
import { BooleanField, EditButton, NumberField } from "react-admin"
import { resourcesMap } from "../../constants"
import { surveyConfigs } from "../../constants/survey.constants"
import { SurveyInstanceRecord } from "../../types"
import { parseSurveyMapId } from "../../utils"
import { Datagrid, DateField, FunctionField, ReferenceField, TextField } from "../_design"
import SendEmailsButton from "./datagrid-buttons/SendEmailsButton"
import TestSurveyButton from "./datagrid-buttons/TestSurveyButton"
import { SurveyTypeChipField } from "./fields/SurveyTypeChipField"

const SurveyInstanceDatagrid = () => {
  return (
    <Datagrid
      rowClick="show"
      size="small"
      sx={{
        "& .column-id": { maxWidth: "60px", textAlign: "center" },
        "& .column-type": { maxWidth: "130px" },
      }}
    >
      <NumberField source="id" textAlign="left" />
      <TextField source="name" />
      <FunctionField
        source="type"
        render={(r: SurveyInstanceRecord) => <SurveyTypeChipField surveyMapId={r?.surveyMapId} />}
      />
      <FunctionField
        label="Template"
        render={(r: SurveyInstanceRecord) => {
          const { typeId, surveyId } = parseSurveyMapId(r?.surveyMapId)
          const { resource } = surveyConfigs[typeId]

          return (
            <ReferenceField source="surveyId" record={{ id: 0, surveyId }} reference={resource}>
              <TextField source="name" />
            </ReferenceField>
          )
        }}
      />

      <ReferenceField source="companyId" reference={resourcesMap.companies.basePath} link="show">
        <TextField source="name" />
      </ReferenceField>

      <ReferenceField source="teamId" reference={resourcesMap.teams.basePath} link="show">
        <TextField source="name" />
      </ReferenceField>

      {/* Remaining Time */}
      <DateField source="startDate" />
      <DateField source="endDate" />
      <BooleanField label="Anonymous" source="isAnonymous" />
      <FunctionField
        label="Responded"
        render={(r: SurveyInstanceRecord) => `${r?.respondedTotal ?? 0}/${r?.invitedTotal ?? 0}`}
      />
      <TestSurveyButton />
      <SendEmailsButton />
      <EditButton />
    </Datagrid>
  )
}

export default SurveyInstanceDatagrid
