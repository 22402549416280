import React, { forwardRef } from "react"
import { useTranslate } from "react-admin"
import Button from "@mui/material/Button"
import { useTransformImageFile } from "../hooks"
import { convertFileToBase64 } from "../../../ra-providers/dataProvider-utils/images-handler"
import { FileToUploadProps } from "../../../types"
import { Box, SxProps } from "@mui/material"

type Props = {
  sx?: SxProps
  icon?: React.ReactElement | null
  label?: string
  onUpload?: (base64Image: string) => void
}

const UploadPictureButton = forwardRef<HTMLButtonElement, Props>(
  ({ sx, icon, label = "Upload picture", onUpload = () => {} }, ref) => {
    const translate = useTranslate()
    const transformImage = useTransformImageFile()

    const handleOnUpdate = async (e: React.ChangeEvent<HTMLInputElement>) => {
      const fileToUpload: FileToUploadProps = transformImage(e)

      if (fileToUpload) {
        const base64Image = await convertFileToBase64(fileToUpload)
        onUpload(base64Image)
      }
    }

    return (
      <>
        <input
          id="upload-picture"
          accept="image/*"
          type="file"
          style={{ display: "none" }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleOnUpdate(e)}
        />
        <Box component="label" htmlFor="upload-picture" sx={sx}>
          <Button
            ref={ref}
            color="primary"
            variant="text"
            fullWidth
            startIcon={icon}
            component="span"
          >
            {translate(label)}
          </Button>
        </Box>
      </>
    )
  }
)

export default UploadPictureButton
