import React, { FC } from "react"
import { Edit } from "../../_design"
import ScaleSurveyForm from "./ScaleSurveyForm"

const ScaleSurveyEdit: FC = () => {
  return (
    <Edit>
      <ScaleSurveyForm />
    </Edit>
  )
}

export default ScaleSurveyEdit
