import React, { FC } from "react"
import { ArrayField, Datagrid, FunctionField, TextField, useRecordContext } from "react-admin"
import { ColumnLabel } from "../../types/matrix-survey"
import { ResultsFetchWrapper } from "./ResultsFetchWrapper"
import LineChartField from "./charts/line-chart-field/LineChartField"

const MatrixScaleResults: FC = () => {
  return (
    <ResultsFetchWrapper>
      <MatrixScaleDatagridResults />
    </ResultsFetchWrapper>
  )
}

const MatrixScaleDatagridResults = () => {
  const record = useRecordContext()

  const columnLabels = new Map<number, string>()
  record.columnLabels.forEach((columnLabel: ColumnLabel) =>
    columnLabels.set(columnLabel.weight, columnLabel.label)
  )

  return (
    <Datagrid>
      <FunctionField
        source="chapter"
        render={(partialRecord: any) =>
          `${record["chapters"].findIndex((chapter: any) => chapter.id === partialRecord.id) + 1}. ${partialRecord.name}`
        }
      />
      <ArrayField source="questions">
        <Datagrid>
          <TextField source="text" />
          <LineChartField yColumnsLabels={columnLabels} />
        </Datagrid>
      </ArrayField>
    </Datagrid>
  )
}

export default MatrixScaleResults
