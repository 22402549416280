import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft"
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight"
import Button from "@mui/material/Button"
import CardActions from "@mui/material/CardActions"
import React, { FC, useContext } from "react"
import { WizardContext } from "../context/WizardContextProvider"

const styles = {
  actions: {
    justifyContent: "space-around",
    backgroundColor: "background.default",
  },
}

const StepperNavigation: FC = () => {
  const { steps, handleBack, handleSkip, handleNext, activeStep, isStepOptional } =
    useContext(WizardContext)
  const stepsCount: number = steps.length

  return (
    <CardActions sx={styles.actions}>
      <Button size="small" disabled={activeStep === 0} onClick={handleBack}>
        <KeyboardArrowLeft />
        Back
      </Button>
      {isStepOptional(activeStep) && (
        <Button size="small" variant="contained" color="primary" onClick={handleSkip}>
          Skip
        </Button>
      )}
      <Button size="small" variant="contained" color="primary" onClick={handleNext}>
        {activeStep === stepsCount - 1 ? "Finish" : "Next"}
        <KeyboardArrowRight />
      </Button>
    </CardActions>
  )
}

export default StepperNavigation
