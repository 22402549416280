import simpleRestProvider from "ra-data-simple-rest"
import { CreateParams, DataProvider, UpdateParams, fetchUtils } from "react-admin"
import handleImagesAndRespond from "./dataProvider-utils/images-handler"

// TODO: Fix anys

const API_PREFIX: string = import.meta.env.VITE_API_PREFIX

const CLIENT_CACHE_PERIOD_IN_SECONDS: number = 30

type UserLocalStorage = {
  firstName: string
  lastName: string
  email: string
  picture: string | null
  token: string
}

const httpClient = (
  url: string,
  options: fetchUtils.Options = {}
): Promise<{
  status: number
  headers: Headers
  body: string
  json: any
}> => {
  const userRecord = localStorage.getItem("user")
  const user = userRecord && (JSON.parse(userRecord) as UserLocalStorage)

  if (user && user?.token)
    options.user = {
      authenticated: true,
      token: `Bearer ${user?.token}`,
    }

  return fetchUtils.fetchJson(url, options)
}

type HttpOptions = {
  onSuccess?: (response: any) => void
  onFailure?: (error: any) => void
  headers?: Headers
  method?: string
  body?: string | FormData
}

const httpBaseCall = (url: string, httpOptions: HttpOptions) => {
  const { onSuccess, onFailure, ...restOptions } = httpOptions

  return httpClient(`${API_PREFIX}${url}`, restOptions)
    .then((response) => {
      return response.json
    })
    .then((data) => {
      onSuccess && onSuccess(data)
      return data
    })
    .catch((error) => {
      onFailure && onFailure(error)
    })
}

const httpGet = (url: string, options: HttpOptions = {}) => {
  return httpBaseCall(url, options)
}

const httpPost = (url: string, values: any, options: HttpOptions = {}) => {
  return httpBaseCall(url, {
    ...options,
    method: "POST",
    body: JSON.stringify(values),
  })
}

const httpDelete = (url: string, id: string, options: HttpOptions = {}) => {
  return httpBaseCall(`${url}/${id}`, {
    ...options,
    method: "DELETE",
  })
}

const restDataProvider: DataProvider = simpleRestProvider(API_PREFIX, httpClient)

const myDataProvider = {
  ...restDataProvider,
  create: (resource: string, params: CreateParams<any>) => {
    if (!params.data.images) {
      // fallback to the default implementation
      return restDataProvider.create(resource, params)
    }

    return handleImagesAndRespond("create", restDataProvider, resource, params)
  },
  update: (resource: string, params: UpdateParams<any>) => {
    if (!params.data.images) {
      // fallback to the default implementation
      return restDataProvider.update(resource, params)
    }

    return handleImagesAndRespond("update", restDataProvider, resource, params)
  },
}

export { httpClient, httpPost, httpDelete, httpGet }

export default myDataProvider
