import Typography from "@mui/material/Typography"
import React from "react"
import { Chapter, Question } from "../../../types"

export type GenericQuestionProps<T> = React.FC<{ question: Question; index: number } & T>

type Props<T> = {
  chapter: Chapter
  QuestionComponent: GenericQuestionProps<T>
} & T

export const GenericChapter = <T extends object>({
  chapter: { name, description, questions },
  QuestionComponent,
  ...rest
}: Props<T>) => {
  return (
    <>
      <Typography variant="h5">{name}</Typography>
      <Typography variant="body1">{description}</Typography>
      {questions.map((q: Question, i: number) => (
        <QuestionComponent key={q.id} question={q} index={i} {...(rest as T)} />
      ))}
    </>
  )
}
