import React from "react"
import { versions } from "./release-versions.json"
import ReleaseSection from "./ReleaseSection"
import CompactTabPanel from "../../../_design/tabs/CompactTabPanel"

const ReleasesTab = (props: any) => {
  return (
    <CompactTabPanel {...props}>
      {versions.map((x: any, i: any) => {
        return <ReleaseSection key={i} versionInfo={x} />
      })}
    </CompactTabPanel>
  )
}

export default ReleasesTab
