import React, { FC } from "react"
import { Show, SimpleShowLayout, TextField, TabbedShowLayout, Tab } from "../_design"
// @ts-ignore
import { ShowSplitter } from "ra-compact-ui"
import { Box } from "@mui/material"
import {
  ReferenceField,
  ReferenceManyField,
  ArrayField,
  SingleFieldList,
  ChipField,
} from "react-admin"
import { resourcesMap } from "../../constants"
import SurveyInstanceDatagrid from "../survey-instances/SurveyInstanceDatagrid"

const TeamsShow: FC = () => {
  return (
    <Show component="div">
      <ShowSplitter
        leftSideProps={{
          md: 4,
        }}
        rightSideProps={{
          md: 12,
        }}
        leftSide={
          <SimpleShowLayout>
            <Box my={2} textAlign="center">
              <TextField source="name" label={false} sx={{ fontSize: 24 }} />
            </Box>

            <ReferenceField
              reference={resourcesMap.companies.basePath}
              source="companyId"
              link="show"
            >
              <TextField source="name" />
            </ReferenceField>
            <ArrayField source="members">
              <SingleFieldList linkType={false}>
                <ChipField source="fullName" size="small" />
              </SingleFieldList>
            </ArrayField>
          </SimpleShowLayout>
        }
        rightSide={
          <TabbedShowLayout>
            <Tab label="Surveys">
              <ReferenceManyField reference={resourcesMap.surveyInstances.basePath} target="teamId">
                <SurveyInstanceDatagrid />
              </ReferenceManyField>
            </Tab>
          </TabbedShowLayout>
        }
      />
    </Show>
  )
}

export default TeamsShow
