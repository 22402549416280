import React, { FC } from "react"
import { Navigate, useLocation } from "react-router-dom"
import {
  BooleanField,
  ChipFieldArray,
  Show,
  ShowSplitter,
  SimpleShowLayout,
  Tab,
  TabbedShowLayout,
  TextField,
} from "../_design"
import UserProfileAvatar from "./common/UserProfileAvatar"
import UserTitle from "./common/UserTitle"

const UserShow: FC = () => {
  const location = useLocation()

  if (location.pathname.endsWith("show")) {
    return <Navigate to={`${location.pathname}/activities`} />
  }

  return (
    <Show component="div" title={<UserTitle />}>
      <ShowSplitter
        leftSide={
          <SimpleShowLayout>
            <UserProfileAvatar />
            <TextField source="fullName" />
            <TextField source="email" />
            <ChipFieldArray source="roles" />
            <BooleanField source="isEnabled" />
          </SimpleShowLayout>
        }
        rightSide={
          <TabbedShowLayout>
            <Tab label="Activities" path="activity">
              <div>Empty</div>
            </Tab>
            <Tab label="Daily Costs" path="stats">
              <div>Empty</div>
            </Tab>
          </TabbedShowLayout>
        }
      />
    </Show>
  )
}

export default UserShow
