import PptxGenJS from "pptxgenjs"
import { SurveyTypeEnum, surveyConfigs } from "../../../../constants/survey.constants"
import { httpGet } from "../../../../ra-providers/dataProvider"
import { SurveyInstanceRecord } from "../../../../types"
import { parseSurveyMapId } from "../../../../utils"
import { convertDateToDDMMYYYY } from "../../../../utils/common-utils"
import { createTitleBodySlideMaster } from "./slide-masters"
import {
  addCanvasResultsSlides,
  addIntroductionSlide,
  addOutroductionSlide,
  addTextboxResultsSlides,
} from "./utilities"

export type detailsProps = {
  surveyName: string
  companyName: string
  teamName: string
  surveyType: string
  startDate: string
  endDate: string
}

export const exportPPTX = async (record: SurveyInstanceRecord) => {
  const { typeId } = parseSurveyMapId(record?.surveyMapId)
  const { resultsPath } = surveyConfigs[typeId as SurveyTypeEnum]

  const company = await httpGet(`/companies?filter={"id":[${record.companyId}]}`)
  const team = await httpGet(`/teams?filter={"id":[${record.teamId}]}`)
  const surveyInstance = await httpGet(`/surveyInstances?filter={"id":[${record.id}]}`)
  const surveyResults = await httpGet(`/${resultsPath}?filter={"id":[${record.id}]}`)
  const { chapters } = surveyResults[0]

  const details: detailsProps = {
    surveyName: surveyResults[0]?.name,
    companyName: company[0]?.name,
    teamName: team[0]?.name,
    surveyType: surveyConfigs[surveyInstance[0].type as SurveyTypeEnum]?.name,
    startDate: convertDateToDDMMYYYY(surveyInstance[0].startDate),
    endDate: convertDateToDDMMYYYY(surveyInstance[0].endDate),
  }

  const pptx = new PptxGenJS()
  pptx.layout = "LAYOUT_WIDE"
  pptx.defineSlideMaster(JSON.parse(JSON.stringify(createTitleBodySlideMaster())))

  addIntroductionSlide(pptx, details)

  const chpartesLenght = chapters.length
  for (let chapterIndex = 0; chapterIndex < chpartesLenght; chapterIndex++) {
    switch (details.surveyType) {
      case "Scale Survey":
      case "Slider Survey":
      case "Matrix Survey":
        await addCanvasResultsSlides(pptx, chapters[chapterIndex], chapterIndex + 1)
        break
      case "Textbox Survey":
        await addTextboxResultsSlides(pptx, chapters[chapterIndex])
        break
    }
  }

  addOutroductionSlide(pptx)

  pptx.writeFile({
    fileName: `${details.companyName} - ${details.teamName} - ${details.surveyType} - ${details.startDate} - ${details.endDate}.pptx`,
  })
}

export default exportPPTX
