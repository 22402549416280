import React from "react"
import {
  Chapter,
  GenericSurvey,
  OcaiAnswerPhaseStr,
  OcaiQuestionType,
  Question,
  Step,
} from "../../../types"
import { createSimpleStep } from "../../wizard/helpers"
import { Completed, GenericChapter, IntroWithToCAndGDPRCheckbox } from "../common"
import { OcaiQuestion } from "./OcaiQuestion"

const ANSWER_PHASES: OcaiAnswerPhaseStr[] = ["Current", "Preferred"]

type OcaiAnswer = Record<string, number>

const sumAnswers = (answerValues: OcaiAnswer): number =>
  Object.values(answerValues).reduce((acc, x) => x + acc, 0)

const buildSteps = <T extends object>(survey: GenericSurvey, surveyType: number): Step[] => {
  const steps: Step[] = []
  const { intro, outro, chapters, ...rest } = survey

  steps.push(
    createSimpleStep({
      id: "intro",
      label: "Introduction",
      content: <IntroWithToCAndGDPRCheckbox intro={intro ?? "Welcome to the survey!"} />,
    })
  )

  for (const answerPhase of ANSWER_PHASES)
    chapters.forEach((ch: Chapter) =>
      steps.push(
        createSimpleStep({
          id: ch.id,
          label: ch.name,
          content: (
            <GenericChapter<T>
              chapter={ch}
              QuestionComponent={OcaiQuestion}
              answerPhase={answerPhase}
              {...(rest as T)}
            />
          ),
          questions: ch.questions,
          validate: (
            answers: Record<number | string, OcaiAnswer> = {},
            questions: OcaiQuestionType[] = []
          ) => {
            const isValid = questions.every(
              (q: Question) => sumAnswers(answers[`${q.id}-${answerPhase}`]) === 100
            )

            return {
              isValid,
              error: isValid ? "" : "Please, distribute all 100 points across the options.",
            }
          },
        })
      )
    )

  steps.push(
    createSimpleStep({
      id: "outro",
      label: "Complete",
      hideNav: true,
      content: <Completed message={outro} />,
    })
  )

  return steps
}

export const ocaiStepsBuilder = {
  buildSteps,
}
