import { SurveyTypeEnum } from "../../../constants/survey.constants"
import { surveyService } from "../../../services"
import { Answers, OnCompleteProps } from "../../../types"
import { MatrixStatementAnswer } from "../../../types/matrix-survey"
import { MatrixSurveyAnswersContext, MatrixSurveyAnswersContextProvider } from "../context"
import { surveyStepsBuilder } from "../survey-steps-builder"

export const matrixSurveyWizardDetails = {
  convertSurveyToStepsFn: surveyStepsBuilder.buildSteps,
  provider: MatrixSurveyAnswersContextProvider,
  context: MatrixSurveyAnswersContext,
  onComplete: async (
    { surveySlug, userSlug }: OnCompleteProps,
    answers: Answers<Map<number, MatrixStatementAnswer>>
  ) => {
    const payload = {
      surveySlug,
      memberSlug: userSlug,
      answers: Object.keys(answers).map((key) => ({
        questionId: Number(key),
        value: [...answers[Number(key)].values()],
      })),
    }

    return surveyService.submitResponse(payload, SurveyTypeEnum.Matrix)
  },
}
