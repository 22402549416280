import React, { FC } from "react"

const styles = {
  root: {
    display: "flex",
    flexDirection: "column" as "column",
    margin: 50,
  },
  list: {
    display: "flex",
    flexDirection: "column" as "column",
    gap: 10,
  },
}

const PrivacyPolicy: FC = () => (
  <div style={styles.root}>
    <h1>Политика за личните данни</h1>
    <p>
      <strong>Информация за администратора на лични данни:</strong>
      <br />
      Бикоуз ЕООД е дружество, регистрирано в Търговския регистър на Агенцията по вписванията с ЕИК
      203911156, със седалище и адрес на управление: София, кв. Стрелбище, имейл адрес
      misho@mishostefanov.com, телефон 0886010041 Ние обработваме Вашите лични данни на основание
      на: Изрично съгласие от Вас – целта се посочва за всеки конкретен случай;
    </p>
    <p>
      <strong>СЛЕД ВАШЕ СЪГЛАСИЕ</strong>
      <br />
      Ние обработваме Вашите лични данни на това основание само след изрично, недвусмислено и
      доброволно съгласие от Ваша страна. Ние няма да предвиждаме каквито и да е неблагоприятни
      последици за Вас, ако откажете обработването на личните данни.
      <br />
      Съгласието е отделно основание за обработване на личните ви данни и целта на обработката е
      посочена в него, и не се покрива с целите, изброени в тази политика. Ако ни дадете съответно
      съгласие и до неговото оттегляне или прекратяване на всякакви договорни отношения с Вас
      изготвяме подходящи за вас предложения за продукти/услуги.
      <br />
      На това основание обработваме само данните, за които сте ни дали изричното си съгласие.
      Конкретните данни се определят за всеки индивидуален случай. Обикновено данните включват:
      <br />
      <ul style={styles.list}>
        <li>Имена;</li>
        <li>Имейл адрес;</li>
        <li>Работно място;</li>
        <li>Заемана длъжност;</li>
        <li>Технически данни, които автоматично се изпращат към нас, когато използвате сайта;</li>
        <li>IP адрес, информация за устройството, от което посещавате сайта;</li>
        <li>Бисквитки (cookies) за идентифициране на браузъра или устройството Ви и др.</li>
      </ul>
      <br />
      Данните, събрани на това основание, изтриваме при искане от Ваша страна.
    </p>
    <p>
      <strong>ОБРАБОТКА НА АНОНИМИЗИРАНИ ДАННИ</strong>
      <br />
      Ние обработваме Вашите данни за статически цели, това означава за анализи, в които резултатите
      са само обобщаващи и следователно данните са анонимни. Идентифицирането на конкретно лице от
      тази информация е невъзможно.
    </p>
    <p>
      <strong>Как защитаваме Вашите лични данни</strong>
      <br />
      За осигуряване на адекватна защита на данните на компанията и своите клиенти, ние прилагаме
      всички необходими организационни и технически мерки, предвидени в Закона за защита на личните
      данни.
      <br />С цел максимална сигурност при обработка, пренос и съхранение на Вашите данни, може да
      използваме допълнителни механизми за защита като криптиране, псевдонимизация и др.
    </p>
    <p>
      <strong>Права на Потребителите</strong>
      <br />
      Всеки Потребител на Платформата се ползва с всички права за защита на личните данни съгласно
      българското законодателство и правото на Европейския съюз.
      <br />
      Всеки Потребител има право на:
      <br />
      <ul style={styles.list}>
        <li>Информираност (във връзка с обработването на личните му данни от администратора);</li>
        <li>Коригиране (ако данните са неточни);</li>
        <li>Изтриване на личните данни (право „да бъдеш забравен“);</li>
        <li>
          Ограничаване на обработването от страна на администратора или обработващия лични данни;
        </li>
        <li>Преносимост на личните данни между отделните администратори;</li>
        <li>Възражение спрямо обработването на негови лични данни;</li>
        <li>
          Субектът на данни има право и да не бъде обект на решение, основаващо се единствено на
          автоматизирано обработване, включващо профилиране, което поражда правни последствия за
          субекта на данните или по подобен начин го засяга в значителна степен;
        </li>
        <li>
          Право на защита по съдебен или административен ред, в случай, че правата на субекта на
          данни са били нарушени.
        </li>
      </ul>
      <br />
      Потребителят може да поиска изтриване, ако е налице едно от следните условия:
      <ul style={styles.list}>
        <li>
          Личните данни повече не са необходими за целите, за които са били събрани или обработвани
          по друг начин;
        </li>
        <li>
          Потребителят оттегля своето съгласие, върху което се основава обработването на данните и
          няма друго правно основание за обработването;
        </li>
        <li>
          Потребителят данните възразява срещу обработването и няма законни основания за
          обработването, които да имат преимущество;
        </li>
        <li>Личните данни са били обработвани незаконосъобразно;</li>
        <li>
          Личните данни трябва да бъдат изтрити с цел спазването на правно задължение по правото на
          Съюза или правото на държава членка, което се прилага спрямо администратора;
        </li>
        <li>
          Личните данни са били събрани във връзка с предлагането на услуги на информационното
          общество на деца и съгласието е дадено от носещия родителска отговорност за детето.
        </li>
      </ul>
      <br />
      Потребителят има право да ограничи обработването на своите лични данни от страна на
      администратора, когато:
      <ul style={styles.list}>
        <li>
          Оспори точността на личните данни. В този случай ограничаването на обработването е за
          срок, който позволява на администратора да провери точността на личните данни;
        </li>
        <li>
          Обработването е неправомерно, но Потребителят не желае личните данни да бъдат изтрити, а
          изисква вместо това ограничаване на използването им;
        </li>
        <li>
          Администраторът не се нуждае повече от личните данни за целите на обработването, но
          Потребителят ги изисква за установяването, упражняването или защитата на правни претенции;
        </li>
        <li>
          възразява срещу обработването в очакване на проверка дали законните основания на
          администратора имат преимущество пред интересите на Потребителя.
        </li>
      </ul>
    </p>
    <p>
      <strong>Право на преносимост.</strong>
      <br />
      Субектът на данните има право да получи личните данни, които го засягат и които той е
      предоставил на администратор, в структуриран, широко използван и пригоден за машинно четене
      формат и има правото да прехвърли тези данни на друг администратор без възпрепятстване от
      администратора, на когото личните данни са предоставени, когато обработването е основано на
      съгласие или на договорно задължение и обработването се извършва по автоматизиран начин.
      Когато упражнява правото си на преносимост на данните, субектът на данните има право да получи
      и пряко прехвърляне на личните данни от един администратор към друг, когато това е технически
      осъществимо.
    </p>
    <p>
      <strong>Право на възражение.</strong>
      <br />
      Потребителите имат право да възразят пред администратора срещу обработването на личните им
      данни. Администраторът на лични данни е длъжен да прекрати обработването, освен ако не докаже,
      че съществуват убедителни законови основания за обработването, които имат предимство пред
      интересите, правата и свободите на субекта на данни, или за установяването, упражняването или
      защитата на правни претенции. При възразяване срещу обработването на лични данни за целите на
      директния маркетинг обработването следва да се прекрати незабавно.
    </p>
    <p>
      <strong>Жалба до надзорния орган</strong>
      <br />
      Всеки Потребител има право да подаде жалба срещу незаконосъобразно обработване на личните му
      данни до Комисия за защита на личните данни или до компетентния съд.
    </p>
    <p>
      <strong>Бисквитки</strong>
      <br />
      Culture and People използва бисквитки, уеб отметки и други подобни технологии (“автоматични
      инструменти за събиране на данни”) във връзка с нашите услуги. Правим това, за да разберем как
      Потребителите използват нашите услуги и да подобрим потребителския им опит. Повече информация
      за инструментите за автоматично събиране на данни ще намерите на адрес:
      www.allaboutcookies.org.
    </p>
    <p>
      Какво са “бисквитките”?
      <br />
      “Бисквитките” или “Cookies са текстови компютърни данни, съхранявани в браузъра на
      потребителя, предназначени за използване от уеб страници. Тези данни позволяват да се
      идентифицира устройството на потребителя и правилното поведение на дадена уеб страница,
      съобразено с неговите индивидуални предпочитания. “Бисквитките” обикновено съдържат името на
      уебсайта, бремето за съхранение на крайното устройство и уникален номер.
      <br />
      Как използваме “бисквитки”?
      <br />
      Използваме “Бисквитки” за оптимизирането на използването на платформата. Чрез тях създаваме и
      анонимна статистика (относно поведението на потребителя) с цел подобряване на структурата.
      <br />
      Какви “бисквитки” използваме?
      <br />
      Необходими - Те са необходими за правилното функциониране на сайта или за дадена
      функционалност, която потребителят желае да използва.
      <br />
      Функционални - Те са важни за изпълнението на услугата, защото:
      <ul style={styles.list}>
        <li>
          служат за подобряване на функционалността на услугата и са предназначени да осигурят
          високо ниво на функционалност на услугата.
        </li>
        <li>
          са много важни за функции на услугата. Тяхното заключване ще доведе до неправилното
          функциониране на някои процеси.
        </li>
      </ul>
      <br />
      Видове “бисквитки” според времето, за което ще бъдат запазени в устройството на потребителя:
      <br />
      <ul style={styles.list}>
        <li>
          Временни “бисквитки”- Поставени по време на престоя в платформата. Те се заличават, когато
          сесията е прекратена.
        </li>
        <li>
          Постоянни “бисквитки”- Те остават в устройството на потребителя за определен период или
          без срок на изтичане.
        </li>
      </ul>
      <br />
      Според преследваната цел
      <br />
      <ul style={styles.list}>
        <li>
          Конфигуриране на услугите - Използват настройките, функциите и други услуги на сайта.
        </li>
        <li>
          Безопасност и надеждност на услугата- Осигуряват възможности за проверка на автентичността
          и оптимизират производителността на сайта.
        </li>
        <li>
          Заверка - Предоставят информация, когато потребителят е извън линия (офлайн), така че
          сайтът да може да запази съответната информация и функции.
        </li>
        <li>
          Сесия състояние- записват информацията за това как потребителят използва платформата. Те
          могат да включват съобщения за грешки, които се появяват на някои елементи. “Бисквитките
          се използват за съхранение на т. нар. “състояние на сесията”, за да помогнат за
          подобряване на услугата и повишаване на комфорта на сърфиране.
        </li>
        <li>Процеси -Позволяват гладкото функциониране на платформата и нейните функции.</li>
        <li>
          Анализ и изследвания, одит на аудиторията- Позволява на собственика на платформата да
          разбере по-добре предпочитанията на потребителя си и чрез проведения анализ да подобри и
          развие продукта и услугите. Обикновено се събират анонимни данни за тенденциите на
          процеса, без да се идентифицират индивидуалните потребители или личните им данни.{" "}
        </li>
        <li>
          “Бисквитки” и личните данни - Личните данни, които са събрани от “бисквитките”, могат да
          бъдат използвани единствено за осъществяване на конкретни функции в платформата, свързани
          със самия потребител. личните данни са криптирани, за да се предотврати достъпът до тях от
          неоторизирани лица.
        </li>
        <li>
          изтриване на “бисквитки” и отказ- Ако не искате да разрешавате бисквитките изобщо или
          искате да използвате само определени “бисквитки”, моля разгледайте настройките на браузъра
          си, за да оттеглите по всяко време дадено вече съгласие. Менюто на раздел “Помощ” на
          браузъра ви или уеб сайтът www.allaboutcookies.org съдържат подробна информация за процеса
          на отказ от “бисквитки” за различните браузъри.
        </li>
      </ul>
    </p>
  </div>
)

export default () => <PrivacyPolicy />
