import React, { FC } from "react"
import {
  List,
  Datagrid,
  TextField,
  BooleanField,
  FunctionField,
  EditButton,
  DeleteButton,
} from "../_design"
import ToggleOnIcon from "@mui/icons-material/ToggleOn"
import BlockIcon from "@mui/icons-material/Block"
import { ListProps, RaRecord } from "react-admin"

const CompaniesList: FC<ListProps> = () => {
  return (
    <List>
      <Datagrid rowClick="show">
        <TextField source="name" label="Company Name" />
        <TextField source="industry" />
        <FunctionField render={(x?: RaRecord): number => x?.teams?.length ?? 0} label="Teams" />
        <BooleanField
          source="activeSurvey" // TOOD - FunctionField
          label="Active Survey"
          TrueIcon={ToggleOnIcon}
          FalseIcon={BlockIcon}
        />
        <EditButton />
        <DeleteButton />
      </Datagrid>
    </List>
  )
}

export default CompaniesList
