import {
  bodyPlaceholder,
  masterBodyPlaceholder,
  subtitlePlaceholder,
  titlePlaceholder,
} from "./placeholders"
import PptxGenJS from "pptxgenjs"

// Title Body Slide Master
export const slideMasterTitle = "TITLE_BODY_SLIDE"
const backgroundImagePath = "public/images/regular_slide_background.png"

export const createTitleBodySlideMaster = (): PptxGenJS.SlideMasterProps => {
  const backgroundColor = "FFFFFF"

  return {
    title: slideMasterTitle,
    background: {
      color: backgroundColor,
      path: backgroundImagePath,
    },
    objects: [
      {
        placeholder: masterBodyPlaceholder,
      },
      {
        placeholder: titlePlaceholder,
      },
      {
        placeholder: bodyPlaceholder,
      },
      {
        placeholder: subtitlePlaceholder,
      },
    ],
    slideNumber: { x: 0.3, y: "95%" },
  }
}
