import React, { FC } from "react"
import { ReferenceInput, required, SelectInput, SimpleForm, TextInput } from "react-admin"
import { resourcesMap } from "../../constants"
import { MembersRefArrayInput } from "./inputs/MembersRefArrayInput"

export const TeamsForm: FC = () => {
  return (
    <SimpleForm>
      <ReferenceInput
        source="companyId"
        reference={resourcesMap.companies.basePath}
        validate={required()}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput source="name" label="Team Name" validate={required()} />
      <MembersRefArrayInput />
    </SimpleForm>
  )
}
