import { Box } from "@mui/material"
import React, { Context, FC } from "react"
import { Answers, OnCompleteProps, Step } from "../../types"
import { isSmallScreen } from "../../utils/common-utils"
import { WizardContextProvider } from "./context"
import { AnswersContextProps } from "./context/WizardContextProvider"
import StepContainer from "./parts/StepContainer"
import Stepper from "./parts/Stepper"

const isMobile = isSmallScreen()
const styles = {
  root: {
    width: "100%",
    display: "inline-flex",
    marginTop: isMobile ? "20px" : "40px",
    justifyContent: "center",
    minHeight: "80vh",
  },
  pattern: {
    height: "100px",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    width: "100%",
    background: "url(./images/pattern-3.svg)",
  },
  verticalStepper: {
    display: "inline-block",
    minWidth: "200px",
  },
  step: {
    justifyContent: "center",
    width: "100%",
    maxWidth: isMobile ? "100%" : "60%",
    padding: "10px",
  },
}

type Props = {
  steps?: Step[]
  onComplete: ({ surveySlug, userSlug }: OnCompleteProps, answers: Answers<any>) => Promise<void>
  AnswersProvider: Function
  AnswersContext: Context<AnswersContextProps>
}

const BaseWizard: FC<Props> = ({ steps = [], onComplete, AnswersProvider, AnswersContext }) => {
  return (
    <>
      <AnswersProvider>
        <WizardContextProvider
          steps={steps}
          onComplete={onComplete}
          AnswersContext={AnswersContext}
        >
          <Box sx={styles.root}>
            {!isMobile && <Stepper sx={styles.verticalStepper} />}
            <Box sx={styles.step}>
              <StepContainer />
            </Box>
          </Box>
        </WizardContextProvider>
      </AnswersProvider>
      <footer style={styles.pattern}></footer>
    </>
  )
}

export default BaseWizard
