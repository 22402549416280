import React, { FC } from "react"

const styles = {
  root: {
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "center" as "center",
    alignItems: "center" as "center",
    margin: 50,
  },
  list: {
    display: "flex",
    flexDirection: "column" as "column",
    gap: 10,
  },
}

const TermsAndConditions: FC = () => (
  <div style={styles.root}>
    <h1>Общи условия</h1>
    <ol style={styles.list}>
      <li key={1}>
        <strong>Въведение. </strong>
        Платформата на Culture and People - altius.cultureandpeople.eu е собственост и се управлява
        от Бикоуз ЕООД, ЕИК: 203911156, със седалище и адрес на управление: България, гр. София,
        ж.к. Стрелбище, бл. 11, вх. Г, ап.62. представлявано от Михаил Стефанов.
      </li>
      <li key={2}>
        <strong>Приемане на условията. </strong>
        Вашето приемане на Общите условия става посредством изрично съгласие чрез потвърждаване на
        текста, предложен от платформата “Приемам общите условия за използване и Политиката за
        поверителност”, с което се счита, че Вие приемате да бъдете обвързан/а от Общите условия.
      </li>
      <li key={3}>
        <strong>Употреба. </strong>
        Физическите лица могат да използват тази Платформа единствено в съответствие с правилата и
        условията, които се намират тук за лични и нетърговски цели. Всяка друга употреба е
        забранена. Копирането и разпространението на инструментите не е позволено. Участието в
        дейностите на платформата винаги е по избор.
      </li>
      <li key={4}>
        <strong>Декларация за поверителност. </strong>
        Culture and People се отнася сериозно към въпросите, свързани с неприкосновеността на личния
        живот. За допълнителна информация относно начина, по който използваме информацията, събрана
        от вас, моля, прегледайте нашата “Privacy Policy".
      </li>
      <li key={5}>
        <strong>Описание на услугата. </strong>
        Платформата работи като панел за проучвания свързани с работната среда на различни екипи в
        организации, които доброволно са се съгласили да се включат и да спазват Общите условия.
        Потребителите могат да участват в предложените им проучвания, свързани с услугите, с които
        предлага БИКОУЗ ЕООД.
      </li>
      <li key={6}>
        <strong>Дефиниции. </strong>
        Чл. 1<br />
        За целите на настоящите общи условия долупосочените понятия следва да се разбират в следния
        смисъл:
        <br />
        <strong>Сайт </strong>– altius.cultureandpeople.eu и всички негови подстраници.
        <br />
        <strong>Потребител </strong>– всяко физическо лице, което ползва услуги, които не са
        предназначени за извършване на търговска или професионална дейност, и всяко физическо лице,
        което като страна по договор по този закон действа извън рамките на своята търговска или
        професионална дейност.
        <br />
        <strong>Общи условия </strong>– настоящите Общи условия, които включват условия за ползване,
        бисквитки, формуляри за отказ и всяка друга правно значима информация.
        <br />
        <strong>Лични данни </strong>– информация за физическо лице, която разкрива неговата
        физическа, психологическа, умствена, семейна, икономическа, културна или обществена
        идентичност.
        <br />
        <strong>Платформа </strong>– съвкупност от идейни, програмни и графични решения,
        съставляваща цялостен продукт, предоставящ достъпна среда на потребителя до възможностите на
        интернет пространството.
        <br />
      </li>
      <li key={7}>
        <strong>Права на интелектуална собственост и запазване на собствеността </strong> - ЧЛ. 2
        Освен, ако не е посочено друго, всички материали, включително без ограничение, всички
        концепции, текст, дизайн, графики, рисунки, снимки, видеоклипове, музика и звуци, както и
        всички търговски марки, марки за услуги и търговски наименования, използвани в проучването и
        / или сайтът за проучване и подборът и подреждането им (общо наречени, "съдържание"), са
        предмет на права на интелектуална собственост, включително авторски права, търговски марки и
        патенти или правото да кандидатстват за тяхната регистрация навсякъде по света, притежавани
        или лицензирани от нас, нашите филиали, собственикът на проучването или други трети лица,
        които са собственици на такава интелектуална собственост. Не Ви се предоставя лиценз за
        използване или възпроизвеждане на "съдържание", и всички права върху интелектуалната
        собственост са запазени изрично. Никакъв лиценз за използване, разкриване, изтегляне,
        копиране, разпространение или възпроизвеждане (включително публикуване в уеб сайт, социални
        медии или блог) на "съдържание", или предмета на "съдържание", е предоставен на Вас и всички
        права на интелектуална собственост в него са изрично запазени. Моля, имайте предвид, че
        могат да бъдат предприети правни действия в случай, че всяко неоторизирано използване на
        "съдържание", бъде проследено до Вас. Можете да участвате само в проучването и да използвате
        сайта на проучването по начин, който не нарушава нашите, или на когото и да било друг, права
        на интелектуална собственост.
      </li>
    </ol>
  </div>
)

export default () => <TermsAndConditions />
