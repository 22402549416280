import React, { FC } from "react"
import NumericScaleInput from "./inputs/NumericScaleInput"
import LikertScaleInput from "./inputs/LikertScaleInput"
import {
  ScaleSurveyProps,
  ScaleSurveyExtraProps,
  ScaleInputType,
  QuestionProps,
} from "../../../types"
import { GenericQuestionProps } from "../types"
import { GenericQuestion } from "../common"

const inputsMaps: Record<ScaleInputType, FC<ScaleSurveyProps>> = {
  Numeric: NumericScaleInput,
  Likert: LikertScaleInput,
}

export const ScaleQuestion: FC<GenericQuestionProps<QuestionProps> & ScaleSurveyExtraProps> = ({
  question: { id, text, isRequired },
  index,
  scaleType,
  lowerLimit,
  upperLimit,
  locale,
}) => {
  const scaleInput = inputsMaps[scaleType]
  return (
    <GenericQuestion text={text} isRequired={isRequired} index={index}>
      {React.createElement(scaleInput, {
        questionId: id,
        lowerLimit,
        upperLimit,
        locale,
      })}
    </GenericQuestion>
  )
}
