import React, { FC } from "react"
import { ThemeProvider } from "../../../ra-providers/contexts"
import { AppBar, Box, Typography } from "@mui/material"

const styles = {
  appBar: {
    height: "48px",
    flex: 1,
    display: "flex",
    color: "white",
    justifyContent: "center",
    paddingLeft: "15px",
    backgroundColor: "secondary.main",
  },
  container: {
    marginTop: "48px",
  },
}

const NotAdminLayout: FC = ({ children }) => (
  <>
    <AppBar sx={styles.appBar}>
      <Typography variant="h5" component="h3">{`Culture & People`}</Typography>
    </AppBar>
    <Box sx={styles.container}>{children}</Box>
  </>
)

export default (props: { children: React.ReactNode }) => (
  <ThemeProvider>
    <NotAdminLayout {...props} />
  </ThemeProvider>
)
