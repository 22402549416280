import PostAddIcon from "@mui/icons-material/PostAdd"
// @ts-ignore
import { ShowSplitter } from "ra-compact-ui"
import React, { FC, JSXElementConstructor, ReactElement } from "react"
import {
  CreateButton,
  EditButton,
  RaRecord,
  ReferenceField,
  useListContext,
  useRecordContext,
} from "react-admin"
import { resourcesMap } from "../../constants"
import { CompanyRecord, CompanyTeamRecord } from "../../types"
import {
  ArrayField,
  Datagrid,
  EmailField,
  Show,
  SimpleShowLayout,
  Tab,
  TabbedShowLayout,
  TextField,
} from "../_design"
import UserProfileAvatar from "../users/common/UserProfileAvatar"

const AssignSurveyButton: FC = () => {
  const record = useRecordContext<CompanyTeamRecord>()

  return (
    <CreateButton
      state={{
        record: {
          teamId: record?.id,
          companyId: record?.companyId,
          membersIds: record?.membersIds,
        },
      }}
      icon={<PostAddIcon />}
      resource={resourcesMap.surveyInstances.basePath}
      label="Assign Survey"
    />
  )
}

const BulkEmployeesAssignSurveyButton: FC = () => {
  const { selectedIds, onUnselectItems } = useListContext()
  const company = useRecordContext<CompanyRecord>()

  return (
    <CreateButton
      onClick={onUnselectItems}
      state={{ record: { companyId: company.id, membersIds: selectedIds } }}
      icon={<PostAddIcon />}
      resource={resourcesMap.surveyInstances.basePath}
      label="Assign Survey"
    />
  )
}

const CreateTeamButton = () => {
  const { selectedIds, onUnselectItems } = useListContext()
  const company = useRecordContext<CompanyRecord>()

  return (
    <CreateButton
      onClick={onUnselectItems}
      icon={<PostAddIcon />}
      resource={resourcesMap.teams.basePath}
      label="Create Team"
      state={{ record: { companyId: company.id, membersIds: selectedIds } }}
    />
  )
}

const CompaniesShow: FC = () => {
  return (
    <Show component="div">
      <ShowSplitter
        leftSide={
          <SimpleShowLayout>
            <UserProfileAvatar />
            <TextField source="name" label="Company Name" />
            <TextField source="industry" />
            <TextField source="notes" aria-multiline fullWidth />
          </SimpleShowLayout>
        }
        rightSide={
          <TabbedShowLayout>
            <Tab label="Employees">
              <ArrayField source="members">
                <MembersDatagrid
                  bulkActionButtons={
                    <>
                      <CreateTeamButton />
                      <BulkEmployeesAssignSurveyButton />
                    </>
                  }
                />
              </ArrayField>
            </Tab>

            <Tab label="Teams">
              <ArrayField source="teams">
                <Datagrid bulkActionButtons={false}>
                  <ReferenceField
                    label="Name"
                    source="id"
                    reference={resourcesMap.teams.basePath}
                    link={(record: RaRecord, reference: string) =>
                      `/${reference}/${record.id}/show`
                    }
                  >
                    <TextField
                      source="name"
                      sx={{ fontSize: "18px", left: "-5px", position: "relative" }}
                    />
                    <ArrayField source="members">
                      <MembersDatagrid bulkActionButtons={false} header={<></>} />
                    </ArrayField>
                  </ReferenceField>
                  <AssignSurveyButton />
                  <EditButton resource={resourcesMap.teams.basePath} />
                </Datagrid>
              </ArrayField>
            </Tab>
          </TabbedShowLayout>
        }
      />
    </Show>
  )
}

export default CompaniesShow

type MembersDatagridProps = {
  bulkActionButtons?: false | ReactElement<any, string | JSXElementConstructor<any>> | undefined
  header?: ReactElement
}

const MembersDatagrid: FC<MembersDatagridProps> = ({ bulkActionButtons = false, header }) => {
  return (
    <Datagrid bulkActionButtons={bulkActionButtons} header={header}>
      <TextField source="firstName" label="First Name" />
      <TextField source="lastName" label="Last Name" />
      <EmailField source="email" label="Email" />
      <TextField source="jobTitle" label="Job Title" />
    </Datagrid>
  )
}
