import { Box } from "@mui/material"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import React, { FC, useCallback, useContext, useEffect } from "react"
import { OcaiQuestionType } from "../../../types"
import { RecordAnswersContext } from "../context"
import { GenericQuestionProps } from "../types"
import CircularProgressWithLabel from "./CircularProgressWithLabel"

type AnswerKeys = "answerA" | "answerB" | "answerC" | "answerD"

const MAX_CUMULATIVE_SCORE = 100
const ANSWER_KEYS: AnswerKeys[] = ["answerA", "answerB", "answerC", "answerD"]

const styles = {
  answer: {
    padding: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& b": {
      paddingRight: 1,
    },
    "& p": {
      width: "80%",
    },
  },
  highlightedRow: {
    backgroundColor: "#bff7df",
  },
  input: {
    width: 80,
    paddingX: 1,
    "& input": {
      textAlign: "center",
    },
  },
  header: {
    display: "flex",
    alignItems: "center",
    height: 50,
    paddingLeft: 4,
    backgroundColor: "secondary.main",
    color: "white",
    "& h2": {
      width: "90%",
    },
  },
  progress: {
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    marginY: "10px",
  },
}

const useOcaiQuestionProgress = (questionId: string) => {
  const { getAnswer } = useContext(RecordAnswersContext)
  const [progress, setProgress] = React.useState(100)

  const currentScores = getAnswer(questionId)
  const currentScoresSum = currentScores
    ? Object.values(currentScores).reduce((acc, curr) => acc + curr, 0)
    : 0

  const calculateProgress = useCallback(
    () => setProgress(MAX_CUMULATIVE_SCORE - currentScoresSum),
    [currentScoresSum]
  )

  useEffect(() => {
    calculateProgress()
  }, [calculateProgress])

  return {
    progress,
    calculateProgress,
  }
}

type Props = GenericQuestionProps<OcaiQuestionType> & { answerPhase: string }

export const OcaiQuestion: FC<Props> = ({ question, answerPhase }) => {
  const questionUID = `${question.id}-${answerPhase}`
  const { updateAnswer, getAnswer } = useContext(RecordAnswersContext)
  const { progress, calculateProgress } = useOcaiQuestionProgress(questionUID)
  const currentScores = getAnswer(questionUID)

  const handleOnBlur = () => calculateProgress()
  const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, name: string) => {
    const value = parseInt(e.target.value)

    if (isNaN(value) || value > 100) return

    updateAnswer(questionUID, {
      ...currentScores,
      [name]: value,
    })
  }

  useEffect(() => {
    // Init scores if first time seeing question
    if (currentScores === undefined)
      updateAnswer(questionUID, { answerA: 0, answerB: 0, answerC: 0, answerD: 0 })
  }, [])

  return (
    <Box>
      {ANSWER_KEYS.map((k, i) => (
        <Box key={k} sx={{ ...styles.answer, ...(i % 2 && styles.highlightedRow) }}>
          <Typography variant="body1" component="p">
            {question[k as AnswerKeys]}
          </Typography>

          <TextField
            type="number"
            size="small"
            sx={styles.input}
            placeholder="0"
            value={currentScores?.[k] ?? 0}
            onChange={(e) => onChange(e, k)}
            onBlur={handleOnBlur}
          />
        </Box>
      ))}

      <Box sx={styles.progress}>
        <Typography variant="body1" component="p">
          {progress >= 0 ? "Remaining points" : "Exceeding points"}
        </Typography>
        <CircularProgressWithLabel value={progress} />
      </Box>
    </Box>
  )
}
