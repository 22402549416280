import Step from "@mui/material/Step"
import StepLabel from "@mui/material/StepLabel"
import MuiStepper, { StepperOwnProps } from "@mui/material/Stepper"
import Typography from "@mui/material/Typography"
import React, { FC, useContext } from "react"
import { WizardContext } from "../context/WizardContextProvider"

const Stepper: FC<StepperOwnProps> = (props) => {
  const { steps, skipped, activeStep, isStepOptional, isStepSkipped, errorOnComplete } =
    useContext(WizardContext)
  return (
    <MuiStepper
      {...props}
      activeStep={activeStep}
      orientation="vertical"
      /* skipped={skipped} */
    >
      {steps.map(({ id, label }, index) => {
        const stepProps: { completed: boolean } = { completed: false }
        const labelProps: { optional: JSX.Element } = { optional: <></> }

        if (isStepOptional(index)) {
          labelProps.optional = <Typography variant="caption">Optional</Typography>
        }

        if (isStepSkipped(index)) {
          stepProps.completed = false
        }

        return !errorOnComplete ? (
          <Step key={`${id}-${index}`} {...stepProps}>
            <StepLabel {...labelProps}>{label}</StepLabel>
          </Step>
        ) : null
      })}
    </MuiStepper>
  )
}

export default Stepper
