import React, { FC } from "react"
import Avatar from "@mui/material/Avatar"

const USER_AVATAR_DEFAULT: string = "/images/avatars/avatar_11.png"
const USER_AVATAR_SIZE: number = 15

const styles = {
  avatar: {
    marginBottom: "10px",
    width: USER_AVATAR_SIZE,
    height: USER_AVATAR_SIZE,
    flexShrink: 0,
    flexGrow: 0,
    margin: "0 auto",
  },
}

type Props = {
  record?: {
    fullName: string
    picture: string
  }
}

const UserProfileAvatar: FC<Props> = ({ record }) => (
  <Avatar sx={styles.avatar} alt={record?.fullName} src={record?.picture ?? USER_AVATAR_DEFAULT} />
)

export default UserProfileAvatar
