import AssignmentIcon from "@mui/icons-material/Assignment"
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn"
import BusinessIcon from "@mui/icons-material/Business"
import CalendarViewMonthIcon from "@mui/icons-material/CalendarViewMonth"
import GroupIcon from "@mui/icons-material/Group"
import LinearScaleIcon from "@mui/icons-material/LinearScale"
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer"
import TextFieldsIcon from "@mui/icons-material/TextFields"
import TuneIcon from "@mui/icons-material/Tune"
import { FC } from "react"
import { userRolesGroups } from "./user-roles.constants"

const createResourceEntity = (
  menuLabel: string | undefined,
  basePath: string,
  icon: FC | undefined,
  rolesWithAccess: string[]
) => ({
  options: { label: menuLabel },
  basePath: basePath,
  icon: icon,
  refId: `${basePath.slice(0, -1)}Id`, // removes the plural "s" in the end and concats "Id"
  rolesWithAccess,
})

const userEntity = createResourceEntity(
  "menu.users",
  "users",
  GroupIcon,
  userRolesGroups.management
)

const ocaiSurveysEntity = createResourceEntity(
  "menu.ocaiSurveys",
  "ocaiSurveys",
  QuestionAnswerIcon,
  userRolesGroups.management
)

const ocaiSurveyResult = createResourceEntity(
  undefined,
  "ocaiSurveyResult",
  undefined,
  userRolesGroups.management
)

const companyEntity = createResourceEntity(
  "menu.companies",
  "companies",
  BusinessIcon,
  userRolesGroups.management
)

const responseEntity = createResourceEntity(
  "menu.responses",
  "responses",
  AssignmentTurnedInIcon,
  userRolesGroups.management
)

const scaleSurveyEntity = createResourceEntity(
  "menu.scaleSurveys",
  "scaleSurveys",
  LinearScaleIcon,
  userRolesGroups.management
)

const surveyInstanceEntity = createResourceEntity(
  "menu.surveyInstances",
  "surveyInstances",
  AssignmentIcon,
  userRolesGroups.management
)

const teams = createResourceEntity(undefined, "teams", undefined, userRolesGroups.management)

const allSurveys = createResourceEntity(
  undefined,
  "allSurveys",
  undefined,
  userRolesGroups.management
)

const scaleSurveyResult = createResourceEntity(
  undefined,
  "ScaleSurveyResult",
  undefined,
  userRolesGroups.management
)

// Textboxt survey resources
const textboxSurveyEntity = createResourceEntity(
  "menu.textboxSurveys",
  "textboxSurveys",
  TextFieldsIcon,
  userRolesGroups.management
)

const textboxSurveyResult = createResourceEntity(
  undefined,
  "TextboxSurveyResult",
  undefined,
  userRolesGroups.management
)

const sliderSurveyEntity = createResourceEntity(
  "menu.sliderSurveys",
  "sliderSurveys",
  TuneIcon,
  userRolesGroups.management
)

const sliderSurveyResult = createResourceEntity(
  undefined,
  "sliderSurveyResult",
  undefined,
  userRolesGroups.management
)

const matrixSurveyEntity = createResourceEntity(
  "menu.matrixSurveys",
  "matrixSurveys",
  CalendarViewMonthIcon,
  userRolesGroups.management
)

const matrixSurveyResult = createResourceEntity(
  undefined,
  "matrixSurveyResult",
  undefined,
  userRolesGroups.management
)

const employees = createResourceEntity(
  undefined,
  "employees",
  undefined,
  userRolesGroups.management
)

export const resourcesMap = {
  //Users resources
  users: userEntity,

  //Companies resources
  companies: companyEntity,
  teams,
  employees,

  //Responses resources
  responses: responseEntity,

  // Surveys

  // Survey Instances resources
  surveyInstances: surveyInstanceEntity,

  // All Surveys resources
  allSurveys,

  // Scale Surveys resources
  scaleSurveys: scaleSurveyEntity,
  scaleSurveyResult,

  // Textbox survey resources
  textboxSurveys: textboxSurveyEntity,
  textboxSurveyResult,

  // Slider survey resources
  sliderSurveys: sliderSurveyEntity,
  sliderSurveyResult,

  ocaiSurveys: ocaiSurveysEntity,
  ocaiSurveyResult,

  // Matrix Surveys resources
  matrixSurveys: matrixSurveyEntity,
  matrixSurveyResult,
}
