import React, { FC } from "react"
import { Edit } from "../../_design"
import { OcaiSurveyForm } from "./OcaiSurveyForm"

export const OcaiSurveyEdit: FC = () => {
  return (
    <Edit>
      <OcaiSurveyForm />
    </Edit>
  )
}
